import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height: number;
  width: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_email
 */
const EMailIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('email-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="email icon">
      <title>EmailIcon</title>
      <path
        fill={fill}
        d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"
      />
    </svg>
  );
};

export default EMailIcon;
