import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getDictionary } from '../../store/locales';
import { getCookiePreferences, setCookiePreferences } from '../../store/app';

import { CookiePreferences } from '../../lib/cookie-preferences';

import ToggleSwitch from '../toggle-switch/toggle-switch';
import Button from '../button/button';

import * as styles from './styles.module.css';

interface Props {
  openSettings?: boolean;
}

const CookieSettings = ({ openSettings }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const cookiePreferences = useSelector(getCookiePreferences);
  const locales = useSelector(getDictionary);
  const [showSettings, setShowSettings] = useState(openSettings);
  const [cookiePrefs, setCookiePrefs] = useState<CookiePreferences>(
    cookiePreferences || { mapsAccepted: true, analyticsAccepted: true }
  );

  const toggleMapsSetting = () => {
    setCookiePrefs((prevState) => {
      return { ...prevState, mapsAccepted: !prevState.mapsAccepted };
    });
  };

  const toggleAnalyticsSetting = () => {
    setCookiePrefs((prevState) => {
      return { ...prevState, analyticsAccepted: !prevState.analyticsAccepted };
    });
  };

  const handleAccept = () => {
    dispatch(setCookiePreferences(cookiePrefs));
  };

  return (
    <div className={styles.settingsContainer}>
      <p>{locales.cookieDisclaimerText}</p>
      <div className={styles.buttons}>
        {showSettings ? (
          <>
            <p className={styles.settingsSwitch}>
              {locales.cookieMapsText}
              <ToggleSwitch
                checked={cookiePrefs.mapsAccepted}
                onChange={toggleMapsSetting}
              />
            </p>
            <p className={styles.settingsSwitch}>
              {locales.cookiesTrackingText}
              <ToggleSwitch
                checked={cookiePrefs.analyticsAccepted}
                onChange={toggleAnalyticsSetting}
              />
            </p>
          </>
        ) : (
          <Button
            className={styles.buttonSettings}
            iconClassName={styles.settingsIcon}
            iconName="settings"
            onClick={() => setShowSettings(true)}
            text={locales.settings}
          />
        )}
        <Button
          className={styles.buttonAccept}
          iconName="check"
          onClick={handleAccept}
          text={!showSettings ? locales.acceptAll : locales.saveSelection}
        />
      </div>
    </div>
  );
};

export default CookieSettings;
