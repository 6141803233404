/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * Whatsapp Icon
 * @param {Object} props The icon properties.
 * @return {Element}
 */
const WhatsAppIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className = ''} = props;

  const iconClassNames = cx('whatsapp-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      aria-label="Whatsapp icon">
      <title>WhatsAppIcon</title>
      <path
        fill={fill}
        d="M18,36 C27.9411255,36 36,27.9411255 36,18 C36,8.0588745 27.9411255,0 18,0 C8.0588745,0 0,8.0588745 0,18 C0,27.9411255 8.0588745,36 18,36 Z M22.275,19.764 C22.5,19.881 22.644,19.944 22.689,20.034 C22.743,20.133 22.725,20.583 22.5,21.096 C22.32,21.6 21.384,22.086 20.97,22.104 C20.556,22.122 20.547,22.428 18.306,21.447 C16.065,20.466 14.715,18.072 14.607,17.919 C14.499,17.766 13.743,16.677 13.779,15.57 C13.824,14.472 14.4,13.95 14.634,13.734 C14.85,13.5 15.093,13.473 15.246,13.5 L15.669,13.5 C15.804,13.5 15.993,13.446 16.164,13.905 L16.785,15.588 C16.839,15.705 16.875,15.84 16.794,15.984 L16.551,16.353 L16.2,16.731 C16.092,16.839 15.966,16.956 16.092,17.181 C16.2,17.415 16.65,18.162 17.28,18.783 C18.099,19.575 18.819,19.836 19.035,19.953 C19.251,20.079 19.386,20.061 19.521,19.917 L20.25,19.071 C20.421,18.846 20.565,18.9 20.772,18.972 L22.275,19.764 L22.275,19.764 Z M18,9 C20.3869484,9 22.6761336,9.94821156 24.363961,11.636039 C26.0517884,13.3238664 27,15.6130516 27,18 C27,22.9705627 22.9705627,27 18,27 C16.227,27 14.58,26.487 13.185,25.605 L9,27 L10.395,22.815 C9.513,21.42 9,19.773 9,18 C9,13.0294373 13.0294373,9 18,9 L18,9 Z M18,10.8 C14.0235498,10.8 10.8,14.0235498 10.8,18 C10.8,19.548 11.286,20.979 12.114,22.149 L11.25,24.75 L13.851,23.886 C15.021,24.714 16.452,25.2 18,25.2 C21.9764502,25.2 25.2,21.9764502 25.2,18 C25.2,14.0235498 21.9764502,10.8 18,10.8 Z"
      />
    </svg>
  );
};

export default WhatsAppIcon;
