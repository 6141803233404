.loading {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 20;
}
.spinner {
  display: block;
  fill: var(--main-color);
  filter: brightness(40%);
  height: 10vh;
  width: 10vw;
  animation: rotator 1.5s linear infinite;
}
.path {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: var(--main-color);
  filter: brightness(40%);
  fill: none;
  stroke-width: 6;
  stroke-linecap: round;
  animation: dash 1.5s ease-in-out infinite;
}
.hidden {
  display: none;
}
@-moz-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-o-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-moz-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@-o-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
