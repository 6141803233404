/**
 * Returns the values from the given schema:
 * /category/:categoryId?/poi/:poiSlug?
 */
export function parsePathname(pathname: string): {
  page: string;
  category: string;
  pagePoi: string;
  poiSlug: string;
} {
  const [page, category, pagePoi, poiSlug] = pathname
    .replace('/', '')
    .split('/');

  return {
    page,
    category,
    pagePoi,
    poiSlug
  };
}
