import React from 'react';
import cx from 'classnames';
import * as categoryIcons from '../../../assets/category-icons/category-images';

import * as styles from './styles.module.css';

import { Category } from '../../../typescript/poi';

type Props = {
  category?: Category;
  onClick: (event: React.MouseEvent<HTMLAnchorElement>) => void;
  title?: string;
  className?: string;
};

/**
 * Returns one category icon.
 */
export default function CategoryIcon(props: Props): React.ReactElement | null {
  const { category, onClick, title, className } = props;
  const classNames = cx({
    [styles.category]: true,
    [className || '']: className
  });
  if (!category) {
    return null;
  }

  return (
    <a id={category.id} onClick={onClick} className={classNames}>
      <img
        alt={category.id}
        className={styles.categoryIcon}
        src={categoryIcons[category.id]}
      />
      {title || category.id}
    </a>
  );
}
