.toggleSwitch {
  position: relative;
  display: inline-block;
  width: 2.59375em;
  height: 1.3125em;
  align-self: center;
  flex-shrink: 0;
}
.toggleSwitch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  background-color: #ccc;
  transition: 0.4s;
}
.slider:before {
  position: absolute;
  content: '';
  height: 0.9375em;
  width: 0.9375em;
  left: 0.1875em;
  bottom: 0.1875em;
  background-color: #fff;
  transition: 0.4s;
}
input:checked + .slider {
  background-color: rgb(230, 0, 125);
}
input:focus + .slider {
  box-shadow: 0px 0px 2px rgba(230, 0, 125, 0.14),
    0px 2px 2px rgba(230, 0, 125, 0.12), 0px 1px 3px rgba(230, 0, 125, 0.2);
}
input:checked + .slider:before {
  transform: translateX(1.25em);
}
/* Rounded sliders */
.slider.round {
  border-radius: 2.125em;
}
.slider.round:before {
  border-radius: 50%;
}
