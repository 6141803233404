import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * The CookedFood icon
 */
const CookedFoodIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('hot-food-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="hot-food icon">
      <title>CookedFoodIcon</title>
      <path
        fill={fill}
        d="M14.86,16.75a.71.71,0,0,1-.48-.18c-1.27-1.16-.58-2.35-.07-3.21s.84-1.54,0-2.32a.58.58,0,0,1,0-.87.72.72,0,0,1,1,0c1.6,1.46.78,2.86.24,3.78s-.68,1.28-.16,1.75a.58.58,0,0,1,0,.87A.71.71,0,0,1,14.86,16.75Zm5.43-.18a.58.58,0,0,0,0-.87c-.52-.47-.39-.82.16-1.75s1.36-2.32-.24-3.78a.72.72,0,0,0-1,0,.58.58,0,0,0,0,.87c.85.78.59,1.33,0,2.32s-1.2,2,.07,3.21a.72.72,0,0,0,1,0Zm5,0a.58.58,0,0,0,0-.87c-.52-.47-.39-.82.16-1.75s1.36-2.32-.24-3.78a.72.72,0,0,0-1,0,.58.58,0,0,0,0,.87c.85.78.59,1.33,0,2.32s-1.2,2,.07,3.21a.72.72,0,0,0,1,0Zm5.58,4h-2V19.19H11.21v1.35h-2a.68.68,0,1,0,0,1.35h2v5.41A2.71,2.71,0,0,0,13.91,30H26.09a2.71,2.71,0,0,0,2.71-2.71V21.89h2a.68.68,0,1,0,0-1.35Z"
      />
    </svg>
  );
};

export default CookedFoodIcon;
