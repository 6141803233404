.container {
  font-size: 1.5rem;
  position: relative;
  max-width: 500px;
  margin: 0 auto;
}

.selectContainer {
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  padding: 0;
}

.selectContainerCollapsed {
  display: none;
}

.header {
  padding: 0.4rem;
  background-color: var(--secondary-background-color);
  cursor: pointer;
}

.headerActivated {
  background-color: var(--primary-background-color);
  color: var(--secondary-color);
}

.option {
  padding: 0.4rem;
  background-color: var(--tertiary-background-color);
  cursor: pointer;
}

.optionActive {
  background-color: rgba(var(--tertiary-background-color-rgb), 0.2);
  color: var(--secondary-color);
}
