import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * https://design.google.com/icons/#ic_close
 */
const PositionMarkerIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('position-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="position icon">
      <title>PositionMarker</title>
      <path
        fill={fill}
        d="M12,0c-6.616,0 -12,5.384 -12,12c0,6.617 5.384,12 12,12c6.617,0 12,-5.383 12,-12c0,-6.616 -5.383,-12 -12,-12M12,3c4.971,0 9,4.03 9,9c0,4.971 -4.029,9 -9,9c-4.97,0 -9,-4.029 -9,-9c0,-4.97 4.03,-9 9,-9"
      />
    </svg>
  );
};

export default PositionMarkerIcon;
