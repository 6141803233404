.container {
  position: relative;
  background-color: currentColor;
  color: currentColor;
  padding: 0.5em 1.5em;
  align-self: center;
  padding-right: 6em;
  border-radius: 4px;
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  cursor: pointer;
}
.text {
  display: inline-block;
  width: 100%;
  text-align: center;
  color: #fff;
}
.icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 1.3125em;
  width: 1.25em;
  padding: 0.5625em 1.625em;
  background: #fff;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  fill: currentColor;
  box-sizing: initial;
}
