import { useSelector } from 'react-redux';
import React from 'react';
import { Helmet } from 'react-helmet';

import config from '../../config';

import { currentLanguageSelector, getDictionary } from '../../store/locales';
import { getActivePoi } from '../../store/pois';

/**
 * The HTML header metadata
 */
function Meta(): React.ReactElement {
  const locales = useSelector(getDictionary);
  const language = useSelector(currentLanguageSelector);
  const poi = useSelector(getActivePoi);

  const title = (poi && poi.address.name) || process.env.PROJECT_NAME;
  const fbDescription = poi
    ? locales.poiShareFacebookCopy
    : locales.pageShareFacebookCopy;
  const twitterDescription = poi
    ? locales.poiShareTwitter
    : locales.pageShareTwitter;

  return (
    <Helmet>
      <title>{locales.title}</title>

      <meta name="keywords" content={locales.pageKeywords} />

      <meta property="og:site_name" content={locales.title} />
      <meta property="og:title" content={title} />
      <meta property="og:type" content="website" />
      <meta property="og:description" content={fbDescription} />
      <meta property="og:url" content={config.webRoot} />
      <meta property="og:image" content={config.shareImage} />
      <meta property="og:locale" content={language} />
      <meta property="og:locale:alternative" content="en" />
      <meta property="og:locale:alternative" content="de" />
      <meta property="og:locale:alternative" content="pl" />
      <meta property="og:locale:alternative" content="ar" />
      <meta property="fb:admins" content={config.fb.handle} />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:site" content={config.twitter.handle} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={twitterDescription} />
      <meta name="twitter:image" content={config.twitter.shareImage} />
      <meta name="twitter:image:alt" content={locales.title} />
    </Helmet>
  );
}

export default Meta;
