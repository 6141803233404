import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * The map icon.
 * @param {Object} props The icon properties.
 * @return {Element}
 */
const MapIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className = ''} = props;

  const iconClassNames = cx('map-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      aria-label="map arrow icon">
      <title>mapIcon</title>
      <path
        fill={fill}
        d="M12,16 L6,13.89 L6,2 L12,4.11 L12,16 Z M17.5,0 C17.44,0 17.39,0 17.34,0 L12,2.1 L6,0 L0.36,1.9 C0.15,1.97 0,2.15 0,2.38 L0,17.5 C0,17.7761424 0.223857625,18 0.5,18 C0.55,18 0.61,18 0.66,17.97 L6,15.9 L12,18 L17.64,16.1 C17.85,16 18,15.85 18,15.62 L18,0.5 C18,0.223857625 17.7761424,0 17.5,0 Z"
      />
    </svg>
  );
};
export default MapIcon;
