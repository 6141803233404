.navigation {
  font-size: 1.5rem;
  position: relative;
  flex-grow: 1;
  background-color: var(--secondary-background-color);
  margin: 0;
  padding-left: 0;
  padding-top: 5.583333333333333em;
  padding-bottom: 0.833333333333333em;
  list-style-type: none;
  font-weight: bold;
  line-height: 1.6;
  text-align: center;
  color: var(--main-color);
}
.navigationItem a {
  display: inline-block;
  padding: 0.416666666666667em 0;
}
.hidden {
  display: none;
}
