import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * The locker icon
 */
const LockerIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('locker-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="locker icon">
      <title>LockersIcon</title>
      <path
        fill={fill}
        d="M17.72,7H10.15A1.15,1.15,0,0,0,9,8.15V31.23a1.15,1.15,0,0,0,1.15,1.15h7.57a1.15,1.15,0,0,0,1.15-1.15V8.15A1.15,1.15,0,0,0,17.72,7ZM10.41,20.81a.7.7,0,0,1,1.41,0v2.75a.7.7,0,0,1-1.41,0Zm0-2.4a.7.7,0,1,1,.7.7A.7.7,0,0,1,10.41,18.41Zm7,12.14h-7V29.13h7Zm0-17h-7V12.15h7Zm0-3.13h-7V9h7ZM29.85,7H22.28a1.15,1.15,0,0,0-1.15,1.15V31.23a1.15,1.15,0,0,0,1.15,1.15h7.57A1.15,1.15,0,0,0,31,31.23V8.15A1.15,1.15,0,0,0,29.85,7ZM22.54,20.81a.7.7,0,0,1,1.41,0v2.75a.7.7,0,0,1-1.41,0Zm0-2.4a.7.7,0,1,1,.7.7A.7.7,0,0,1,22.54,18.41Zm7,12.14h-7V29.13h7Zm0-17h-7V12.15h7Zm0-3.13h-7V9h7Z"
      />
    </svg>
  );
};

export default LockerIcon;
