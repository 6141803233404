.container {
  display: flex;
  font-size: 1rem;
  height: 2.375em;
  min-width: 6.25em;
  align-items: center;
  position: relative;
  background-color: currentColor;
  color: currentColor;
  padding: 0.5em 1.5em;
  align-self: center;
  border-radius: 4px;
  box-shadow: var(--box-shadow);
  cursor: pointer;
  border: none;
}
.container.hasIcon {
  position: relative;
  padding-right: 5em;
}
.container:disabled .text {
  color: #76ac27;
}
.container:disabled .icon {
  fill: #dbe9c1;
}
.container > * {
  color: #fff;
}
.text {
  color: #fff;
}
.iconContainer {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  top: 0;
  right: 0;
  bottom: 0;
  width: 3.75em;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
