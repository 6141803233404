import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';

import {
  getCategory,
  getParentCategory,
  removeCategory,
  selectCategory
} from '../store/filter';
import { disableCompareMode, isAppOffline } from '../store/app';

import * as styles from './wrapper.module.css';
import { useLocation, useNavigate } from 'react-router';
import { parsePathname } from '../lib/parse-pathname';
import { activatePoi, deactivatePoi } from '../store/pois';
import { categories } from '../constants/categories';

type Props = {
  children: React.ReactNode;
};

/**
 * The app wrapper component.
 */
function Wrapper({ children }: Props): React.ReactElement {
  const parentCategory = useSelector(getParentCategory);
  const storeCategory = useSelector(getCategory);
  const isOffline = useSelector(isAppOffline);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const { category, poiSlug } = parsePathname(location.pathname);

    const isCategoryValid =
      category && categories.find(({ id }) => id === category);

    // This must be refactored to check if there is a poi with this slug.
    // Until now this is only kind of a placeholder.
    const isPoiValid = Boolean(poiSlug);
    dispatch(disableCompareMode());

    if (isCategoryValid) {
      if (storeCategory !== category) {
        dispatch(selectCategory(category));
      }
    } else {
      dispatch(removeCategory());
    }

    if (isCategoryValid && isPoiValid) {
      dispatch(activatePoi(poiSlug));
    } else {
      dispatch(deactivatePoi());
    }

    if (!isCategoryValid && /^\/category/.test(location.pathname)) {
      navigate('/');
    }
  }, [location.pathname, navigate]);

  // @ts-ignore
  const categoryClassname = styles[`wrapper--${parentCategory}`];
  const classes = cx(styles.wrapper, {
    [categoryClassname]: parentCategory,
    [styles.offline]: isOffline
  });

  return <div className={classes}>{children}</div>;
}

export default Wrapper;
