.container {
  position: absolute;
  display: flex;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 0 1.25em 0 25em;
  background-color: #fff;
}
.container:before {
  opacity: 0.3;
}
@media print {
  .container {
    display: block;
    padding: 0;
    background: #fff;
    z-index: 100;
  }
  .container:before {
    opacity: 0;
  }
}
.info {
  display: flex;
  flex: 0 1 30%;
  flex-direction: column;
  width: 30%;
  height: 33.3125em;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}
@media print {
  .info {
    display: block;
    padding: 0;
    page-break-before: always;
  }
}
.info .header {
  position: relative;
  display: flex;
  align-items: center;
  height: 4.875em;
  padding: 0.9375em;
  box-shadow: var(--box-shadow-inset);
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  background-position: 0 -55px;
  overflow: hidden;
  box-sizing: border-box;
}
@media print {
  .info .header {
    background: none;
    box-shadow: none;
  }
}
@media (min-width: 875px) {
  .info .header {
    box-shadow: none;
  }
}
.info .title {
  position: relative;
  color: var(--main-color);
  overflow: hidden;
  direction: ltr;
  font-weight: 900;
  text-overflow: ellipsis;
  white-space: nowrap;
}
html[dir='rtl'] .info .title {
  display: flex;
  flex-direction: row-reverse;
}
.empty {
  border: 4px dotted #fff;
  background-color: transparent;
  cursor: initial;
}
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  height: 100%;
  max-height: 15.625em;
  overflow: auto;
  box-sizing: border-box;
  max-height: none;
  padding: 0 0.9375em;
}
.contentItem {
  display: flex;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 31.25em;
  padding: 0.3125em 0.9375em;
  text-align: left;
  padding: 0.5em 0;
}
.contentItem:before {
  opacity: 0.3;
}
.contentItem:first-child {
  padding-top: 0.9375em;
}
.contentItem:last-child {
  padding-bottom: 0.9375em;
}
.icon {
  flex-shrink: 0;
  fill: currentColor;
  margin-right: 0.625em;
}
html[dir='rtl'] .icon {
  margin-right: 0;
  margin-left: 0.625em;
}
.filter {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 1.5625em;
  padding: 0.4375em 0.9375em;
  background-color: #fff;
  border-bottom: 1px solid var(--main-color);
}
.printButton {
  position: absolute;
  right: 1.25em;
  bottom: 1.25em;
}
@media print {
  .printButton {
    display: none;
  }
}
