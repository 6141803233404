/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://material.io/resources/icons/?search=up&icon=arrow_upward&style=baseline
 */
const ArrowUpwardIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('arrow-upward-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="arrow upward icon">
      <title>ArrowUpwardIcon</title>
      <path fill="none" d="M0 0h24v24H0V0z" />
      <path
        fill={fill}
        d="M4 12l1.41 1.41L11 7.83V20h2V7.83l5.58 5.59L20 12l-8-8-8 8z"
      />
    </svg>
  );
};

export default ArrowUpwardIcon;
