/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_settings
 */
const SettingsIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('settings-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="settings icon">
      <title>Settings</title>
      <path fill={fill} d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
    </svg>
  );
};

export default SettingsIcon;
