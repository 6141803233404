import { useSelector } from 'react-redux';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router';

import pck from '../../../../package.json';

import { getDictionary } from '../../store/locales';

import CookieSettings from '../cookie-disclaimer/cookie-settings';

import * as styles from './page.module.css';

/**
 * The page component. Should render a simple page without header.
 */
function Page(): React.ReactElement {
  const locales = useSelector(getDictionary);
  const { page }: { page?: string } = useParams();
  const content = locales[`${page}Text`] || '';

  return (
    <article className={styles.article}>
      {page === 'privacy' && (
        <>
          <h2>{locales.privacyTitle}</h2>
          <CookieSettings openSettings />
        </>
      )}
      <ReactMarkdown>{content}</ReactMarkdown>
      {page === 'about' && <p>version: {pck.version}</p>}
    </article>
  );
}

export default Page;
