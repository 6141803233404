/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * Twitter icon
 */
const TwitterIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className = ''} = props;

  const iconClassNames = cx('twitter-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      aria-label="twitter icon">
      <title>TwitterIcon</title>
      <path
        fill={fill}
        d="M18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 Z M26.19,13.5 C25.6125,13.7625 24.99,13.935 24.345,14.0175 C25.005,13.62 25.515,12.99 25.755,12.2325 C25.1325,12.6075 24.4425,12.87 23.715,13.02 C23.1225,12.375 22.29,12 21.345,12 C19.5825,12 18.1425,13.44 18.1425,15.2175 C18.1425,15.4725 18.1725,15.72 18.225,15.9525 C15.555,15.8175 13.1775,14.535 11.595,12.5925 C11.3175,13.065 11.16,13.62 11.16,14.205 C11.16,15.3225 11.7225,16.3125 12.5925,16.875 C12.06,16.875 11.565,16.725 11.13,16.5 C11.13,18.0825 12.24,19.3875 13.71,19.68 C13.44,19.755 13.155,19.7925 12.8625,19.7925 C12.66,19.7925 12.4575,19.77 12.2625,19.7325 C12.6675,21 13.845,21.945 15.2625,21.9675 C14.1675,22.8375 12.78,23.3475 11.265,23.3475 C11.01,23.3475 10.755,23.3325 10.5,23.3025 C11.925,24.2175 13.62,24.75 15.435,24.75 C21.345,24.75 24.5925,19.845 24.5925,15.5925 C24.5925,15.45 24.5925,15.315 24.585,15.1725 C25.215,14.7225 25.755,14.1525 26.19,13.5 Z"
      />
    </svg>
  );
};

export default TwitterIcon;
