.wrapper {
  height: 100%;
  width: 100%;
  color: var(--primary-background-color);
  overflow: auto;
  display: flex;
  flex-direction: column;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .wrapper > * {
    flex-shrink: 0;
  }
}
.wrapperMap {
  overflow: hidden;
}
@media (min-width: 580px) {
  .wrapperMap {
    overflow: visible;
  }
}
.wrapper--foodDrink {
  color: var(--food-drink-color);
}
.wrapper--sleep {
  color: var(--sleep-color);
}
.wrapper--hygiene {
  color: var(--hygiene-color);
}
.wrapper--medicalAid {
  color: var(--med-color);
}
.wrapper--informationAdvice {
  color: var(--consulting-color);
}
.wrapper--other {
  color: var(--misc-color);
}
.offline {
  padding-bottom: 2.75rem;
}
.section,
.filterSection,
.categorySection,
.mapSection {
  position: relative;
  width: 100%;
}
.filterSection {
  min-height: 3.75rem;
  background: var(--secondary-background-color);
}
@media (min-width: 875px) {
  .filterSection {
    min-height: 0;
    height: auto;
  }
}
.categorySection {
  background: var(--tertiary-background-color);
  flex-grow: 1;
}
.mapSection {
  display: flex;
  flex: 0 1 100%;
}
.hidden {
  display: none;
}
