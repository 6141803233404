import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * The facebook icon.
 */
const FacebookIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className = ''} = props;

  const iconClassNames = cx('facebook-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 36 36"
      aria-label="facebook arrow icon">
      <title>FaceBookIcon</title>
      <path
        fill={fill}
        d="M18,36 C8.0588745,36 0,27.9411255 0,18 C0,8.0588745 8.0588745,0 18,0 C27.9411255,0 36,8.0588745 36,18 C36,27.9411255 27.9411255,36 18,36 Z M22.5,9 L19.8,9 C17.8117749,9 16.2,10.6117749 16.2,12.6 L16.2,16.2 L13.5,16.2 L13.5,19.8 L16.2,19.8 L16.2,27 L19.8,27 L19.8,19.8 L22.5,19.8 L22.5,16.2 L19.8,16.2 L19.8,13.95 C19.8,13.329 20.079,12.6 20.7,12.6 L22.5,12.6 L22.5,9 Z"
      />
    </svg>
  );
};
export default FacebookIcon;
