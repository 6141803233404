.container {
  position: relative;
  height: auto;
  width: 100%;
  max-width: 100%;
  z-index: 1;
  background-color: #fff;
  box-shadow: var(--box-shadow);
}
.container *,
.container *::before,
.container *::after {
  box-sizing: initial;
}
.container:before {
  opacity: 0.3;
}
@media (min-width: 875px) {
  .container {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 3.4375em;
    left: 0;
    bottom: 0;
    width: 23.75em;
    background-color: #fff;
  }
}
.header {
  position: relative;
  background-color: #fff;
  box-shadow: var(--box-shadow-inset);
  flex-shrink: 0;
}
@media (min-width: 875px) {
  .header {
    box-shadow: none;
  }
}
.title {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: 0.9375em;
  background-position: 0 -55px;
}
.name {
  flex: 0 1 75%;
  direction: ltr;
  color: var(--main-color);
  font-weight: 900;
}
@media (min-width: 875px) {
  .name {
    flex-grow: 1;
  }
}
html[dir='rtl'] .name {
  display: flex;
  flex-direction: row-reverse;
}
.toggle {
  transition: all 0.2s;
}
html[dir='rtl'] .toggle {
  right: auto;
  left: 0.9375em;
}
.toggle svg {
  transition: all 0.2s;
  transform: rotate(-180deg);
}
.toggleOpen svg {
  transform: rotate(0);
}
@media (min-width: 875px) {
  .toggle {
    display: none;
  }
}
.close {
  display: none;
  transition: all 0.2s;
}
@media (min-width: 875px) {
  .close {
    display: block;
  }
}
html[dir='rtl'] .close {
  right: auto;
  left: 0.9375em;
}
.close svg {
  transition: all 0.2s;
  transform: rotate(-180deg);
}
.closeOpen svg {
  transform: rotate(0);
}
.filter {
  display: flex;
  align-items: center;
  position: relative;
  min-height: 1.5625em;
  padding: 0.4375em 0.9375em;
  background-color: #fff;
}
.distance {
  color: var(--main-color);
}
.content {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  max-height: 15.625em;
  overflow: auto;
  box-sizing: border-box;
}
@media (min-width: 875px) {
  .content {
    max-height: none;
    padding: 0 0.9375em;
  }
}
.contentItem {
  display: flex;
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 31.25em;
  padding: 0.3125em 0.9375em;
  text-align: left;
}
.contentItem:before {
  opacity: 0.3;
}
.contentItem:first-child {
  padding-top: 0.9375em;
}
.contentItem:last-child {
  padding-bottom: 0.9375em;
}
@media (min-width: 875px) {
  .contentItem {
    padding: 0.5em 0;
  }
}
.icon,
.iconDirections {
  flex-shrink: 0;
  fill: currentColor;
  margin-right: 0.625em;
}
html[dir='rtl'] .icon,
html[dir='rtl'] .iconDirections {
  margin-right: 0;
  margin-left: 0.625em;
}
.iconDirections {
  position: absolute;
  top: 1.25em;
  right: 0.3125em;
  fill: var(--main-color);
}
@media (min-width: 875px) {
  .iconDirections {
    display: none;
  }
}
html[dir='rtl'] .iconDirections {
  right: auto;
  left: 0.3125em;
}
.iconShare {
  position: absolute;
  top: 0.625em;
  right: 0.9375em;
  width: 1.25em;
  height: 1.25em;
  cursor: pointer;
}
@media (min-width: 875px) {
  .iconShare {
    display: none;
  }
}
.button {
  margin-top: 1em;
  flex-shrink: 0;
}
@media (min-width: 875px) {
  .button {
    width: 12.5em;
  }
}
.button:last-of-type {
  margin-bottom: 1em;
}
.button[disabled] {
  pointer-events: none;
  opacity: 0.3;
}
.directionsButton {
  display: none;
}
@media (min-width: 875px) {
  .directionsButton {
    display: block;
  }
}
.share {
  position: absolute;
  display: none;
  justify-content: space-around;
  bottom: 0;
  width: 100%;
  padding: 1.5625em 0;
  background: #fff;
}
@media (min-width: 875px) {
  .share {
    position: relative;
    display: flex;
  }
}
.shareLink {
  z-index: 1;
}
.shareOffline {
  pointer-events: none;
}
.shareOffline .shareLink {
  opacity: 0.3;
}
.open {
  display: flex;
}
.open:before {
  content: '';
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  background: var(--main-color);
}
.open:after {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background: #fff;
}
a.openLink {
  color: currentColor;
}
.openIcon {
  display: block;
  width: 3em;
  fill: currentColor;
  z-index: 1;
}
.hidden {
  display: none;
}
