import React, { useRef } from 'react';
import cx from 'classnames';

import * as styles from './styles.module.css';

type Props = {
  onChange: (input: string) => void;
  value?: string;
  placeholder: string;
  className?: string;
  onNext?: () => void;
  onPrev?: () => void;
  onEnter?: () => void;
  onEscape?: () => void;
  onBlur?: () => void;
};

/**
 * The input field
 */
const Input = (props: Props): React.ReactElement => {
  const input = useRef<HTMLInputElement>(null);
  /**
   * To bubble the change to the parent call the handler for it.
   */
  const onChange = () => {
    input.current && props.onChange(input.current.value);
  };

  /**
   * on Blur the input
   */
  const blur = () => {
    const { onBlur = () => null } = props;

    input.current && input.current.blur();
    onBlur();
  };

  /**
   * When a key gets pressed in the input
   * @param  {Event} event The keydown event
   */
  const onInputKeyDown = (event: React.KeyboardEvent) => {
    const {
      onNext = () => null,
      onPrev = () => null,
      onEscape = () => null,
      onEnter = () => null
    } = props;

    switch (event.which) {
      case 40:
        // DOWN
        if (!event.shiftKey) {
          event.preventDefault();
          onNext();
        }

        break;
      case 38:
        // UP
        if (!event.shiftKey) {
          event.preventDefault();
          onPrev();
        }

        break;
      case 13:
        // ENTER
        onEnter();
        break;
      case 9:
        // TAB
        blur();
        break;
      case 27:
        // ESC
        blur();
        onEscape();
        break;
      default:
        break;
    }
  };

  const { value, placeholder, className } = props;
  const classes = cx(styles.input, className);

  return (
    <input
      className={classes}
      ref={input}
      type="text"
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onInputKeyDown}
    />
  );
};

export default Input;
