import {
  Category,
  ParentCategoryId,
  SubCategoryId
} from '../../typescript/poi.d';

export const parentCategoryIds: Record<ParentCategoryId, string> = {
  authorities: 'authorities',
  culturalAndRecreationalActivities: 'culturalAndRecreationalActivities',
  education: 'education',
  foodDrink: 'foodDrink',
  hygiene: 'hygiene',
  medicalAid: 'medicalAid',
  other: 'other',
  work: 'work',
  sleep: 'sleep'
};

export const categoryIds: Record<SubCategoryId, string> = {
  accomodation: 'accomodation',
  childrensDoctor: 'childrensDoctor',
  clothingStore: 'clothingStore',
  counselling: 'counselling',
  crisisLine: 'crisisLine',
  dentists: 'dentists',
  drinkingWater: 'drinkingWater',
  entertainment: 'entertainment',
  food: 'food',
  generalPractitioner: 'generalPractitioner',
  governmentAgency: 'governmentAgency',
  gym: 'gym',
  gynecologist: 'gynecologist',
  jobContactPoints: 'jobContactPoints',
  jobMarket: 'jobMarket',
  languageCourse: 'languageCourse',
  languageSchool: 'languageSchool',
  legalAdvice: 'legalAdvice',
  playground: 'playground',
  privateLanguageLessions: 'privateLanguageLessions',
  shelterAgency: 'shelterAgency',
  publicShower: 'publicShower',
  sportsClub: 'sportsClub',
  telecomunication: 'telecomunication',
  travel: 'travel',
  ukrainGovernmentAgency: 'ukrainGovernmentAgency',
  wc: 'wc'
};

export const categories = [
  // FOOD DRINK
  {
    id: parentCategoryIds.foodDrink
  },
  {
    id: categoryIds.food,
    parent: parentCategoryIds.foodDrink
  },
  {
    id: categoryIds.drinkingWater,
    parent: parentCategoryIds.foodDrink
  },
  // SLEEP
  {
    id: parentCategoryIds.sleep
  },
  {
    id: categoryIds.accomodation,
    parent: parentCategoryIds.sleep
  },
  {
    id: categoryIds.shelterAgency,
    parent: parentCategoryIds.sleep
  },
  // MEDICAL AID
  {
    id: parentCategoryIds.medicalAid
  },
  {
    id: categoryIds.gynecologist,
    parent: parentCategoryIds.medicalAid
  },
  {
    id: categoryIds.childrensDoctor,
    parent: parentCategoryIds.medicalAid
  },
  {
    id: categoryIds.generalPractitioner,
    parent: parentCategoryIds.medicalAid
  },
  {
    id: categoryIds.dentists,
    parent: parentCategoryIds.medicalAid
  },
  // Authorities
  {
    id: parentCategoryIds.authorities
  },
  {
    id: categoryIds.governmentAgency,
    parent: parentCategoryIds.authorities
  },
  {
    id: categoryIds.counselling,
    parent: parentCategoryIds.authorities
  },
  {
    id: categoryIds.legalAdvice,
    parent: parentCategoryIds.authorities
  },
  {
    id: categoryIds.crisisLine,
    parent: parentCategoryIds.authorities
  },
  {
    id: categoryIds.ukrainGovernmentAgency,
    parent: parentCategoryIds.authorities
  },

  // RECREATION
  { id: parentCategoryIds.culturalAndRecreationalActivities },
  {
    id: categoryIds.gym,
    parent: parentCategoryIds.culturalAndRecreationalActivities
  },
  {
    id: categoryIds.entertainment,
    parent: parentCategoryIds.culturalAndRecreationalActivities
  },
  {
    id: categoryIds.travel,
    parent: parentCategoryIds.culturalAndRecreationalActivities
  },
  {
    id: categoryIds.playground,
    parent: parentCategoryIds.culturalAndRecreationalActivities
  },
  {
    id: categoryIds.sportsClub,
    parent: parentCategoryIds.culturalAndRecreationalActivities
  },
  // EDUCATION
  { id: parentCategoryIds.education },
  {
    id: categoryIds.languageSchool,
    parent: parentCategoryIds.education
  },
  {
    id: categoryIds.languageCourse,
    parent: parentCategoryIds.education
  },
  {
    id: categoryIds.privateLanguageLessions,
    parent: parentCategoryIds.education
  },
  // WORK
  { id: parentCategoryIds.work },
  {
    id: categoryIds.jobMarket,
    parent: parentCategoryIds.work
  },
  {
    id: categoryIds.jobContactPoints,
    parent: parentCategoryIds.work
  },
  // OTHER
  {
    id: parentCategoryIds.other
  },
  {
    id: categoryIds.telecomunication,
    parent: parentCategoryIds.other
  },
  {
    id: categoryIds.wc,
    parent: parentCategoryIds.other
  },
  {
    id: categoryIds.publicShower,
    parent: parentCategoryIds.other
  },
  {
    id: categoryIds.clothingStore,
    parent: parentCategoryIds.other
  }
] as Category[];
