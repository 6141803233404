import { ParentCategoryId } from '../typescript/poi';
import { parentCategoryIds } from './constants/categories';

import * as markerIcons from '../assets/markers/marker-icons';

export default {
  nodeEnv: process.env.NODE_ENV || '',
  googleMapsAPIKey: process.env.GOOGLE_MAPS_API_KEY,
  webRoot: `${process.env.WEB_ROOT}`,
  contactEmail: 'info@refugeo.com',
  shareImage: `${process.env.WEB_ROOT}/assets/share-og.jpg`,
  twitter: {
    handle: '',
    shareImage: `${process.env.WEB_ROOT}/assets/share-twitter.jpg`
  },
  fb: {
    handle: ''
  },
  basePath: {
    local: '/',
    staging: 'https://storage.googleapis.com/refugeo/staging/',
    production: '/'
  },
  cookieName: `${process.env.PROJECT_NAME}-cookie-disclaimer-v1`,
  cookieMaxAge: 31536000,

  whatsappSosId: '',
  dataUrl: `${process.env.BASE_API_URL}/pois`,
  localesUrl: `${process.env.BASE_API_URL}/locales`,
  defaultMapCenter: {
    lat: 48.4029055,
    lng: 11.7211547,
    bounds: {
      south: 48.428537,
      west: 11.638774,
      north: 48.349525,
      east: 11.844757
    }
  },
  clusterStyles: {
    [parentCategoryIds.foodDrink]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.foodDrink],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.foodDrink],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.foodDrink],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.sleep]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.sleep],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.sleep],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.sleep],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.hygiene]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.hygiene],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.hygiene],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.hygiene],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.authorities]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.authorities],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.authorities],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.authorities],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.medicalAid]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.medicalAid],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.medicalAid],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.medicalAid],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.other]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.other],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.other],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.other],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.work]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.work],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.work],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.work],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.culturalAndRecreationalActivities]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.culturalAndRecreationalActivities],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.culturalAndRecreationalActivities],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.culturalAndRecreationalActivities],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ],
    [parentCategoryIds.education]: [
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.education],
        height: 60,
        width: 60,
        anchor: [23, 26],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.education],
        height: 60,
        width: 60,
        anchor: [23, 22],
        textSize: 14
      },
      {
        // @ts-ignore implicitly any
        url: markerIcons[parentCategoryIds.education],
        height: 60,
        width: 60,
        anchor: [23, 18],
        textSize: 14
      }
    ]
  },
  filterTypes: ['convenienceFilter', 'personalFilter', 'category'],
  categoriesColors: {
    [parentCategoryIds.authorities]: '#009de2',
    [parentCategoryIds.education]: '#931980',
    [parentCategoryIds.foodDrink]: '#29ab65',
    [parentCategoryIds.hygiene]: '#f29000',
    [parentCategoryIds.medicalAid]: '#f29000',
    [parentCategoryIds.other]: '#a3887a',
    [parentCategoryIds.culturalAndRecreationalActivities]: '#e6007d',
    [parentCategoryIds.sleep]: '#002a5c',
    [parentCategoryIds.work]: '#1a1a18'
  } as Record<ParentCategoryId, string>,

  categoriesSecondaryColors: {
    [parentCategoryIds.authorities]: '#fae1b9',
    [parentCategoryIds.education]: '#d7cee4',
    [parentCategoryIds.foodDrink]: '#daebc0',
    [parentCategoryIds.hygiene]: '#b4d8db',
    [parentCategoryIds.medicalAid]: '#f2c5c6',
    [parentCategoryIds.other]: '#d7cee4',
    [parentCategoryIds.culturalAndRecreationalActivities]: '#d7cee4',
    [parentCategoryIds.sleep]: '#bddced',
    [parentCategoryIds.work]: '#d7cee4'
  } as Record<ParentCategoryId, string>,

  positionMarkerColor: '#29aae1',
  positionMarkerPath:
    'M30.77,39.89a10.5,10.5,0,1,1,10.5-10.5,10.5,10.5,0,0,1-10.5,10.5Z',
  markerStrokeColor: '#fff'
};

export const mapStyles = [
  {
    featureType: 'administrative.land_parcel',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'administrative.neighborhood',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'landscape',
    elementType: 'geometry',
    stylers: [
      {
        color: '#eaf0e7'
      }
    ]
  },
  {
    featureType: 'poi',
    stylers: [
      {
        visibility: 'off'
      }
    ]
  },
  {
    featureType: 'poi.park',
    stylers: [
      {
        visibility: 'on'
      }
    ]
  },
  {
    featureType: 'poi.park',
    elementType: 'geometry',
    stylers: [
      {
        color: '#b8e7cb'
      }
    ]
  },
  {
    featureType: 'road.highway',
    elementType: 'geometry',
    stylers: [
      {
        color: '#ffcf5e'
      }
    ]
  }
];

export const markerPath = 'assets/markers/';

export const clusterOptions = {
  maxZoom: 10,
  imagePath: 'assets/markers/m'
};
