import { PoiDocument } from '../../../typescript/firestore';
import { Poi } from '../../../typescript/poi';

/**
 * Returns parsed pois api response.
 */
export default function parsePoisResponse(response: Array<PoiDocument>): Poi[] {
  return response.map((document, index) => {
    return {
      id: index,
      meta: {
        public: document.public,
        slug: document.slug
      },
      address: {
        name: document.name,
        city: document.city,
        zip: document.zip,
        street: document.street,
        streetnumber: document.streetnumber,
        email: document.email,
        formattedAddress: document.formattedAddress,
        links: document.links,
        notes: document.notes,
        openingHours: document.openingHours,
        phone: document.phone
      },
      category: {
        accomodation: document.accomodation,
        childrensDoctor: document.childrensDoctor,
        clothingStore: document.clothingStore,
        counselling: document.counselling,
        crisisLine: document.crisisLine,
        dentists: document.dentists,
        drinkingWater: document.drinkingWater,
        entertainment: document.entertainment,
        food: document.food,
        generalPractitioner: document.generalPractitioner,
        governmentAgency: document.governmentAgency,
        gym: document.gym,
        gynecologist: document.gynecologist,
        jobContactPoints: document.jobContactPoints,
        jobMarket: document.jobMarket,
        languageCourse: document.languageCourse,
        languageSchool: document.languageSchool,
        legalAdvice: document.legalAdvice,
        playground: document.playground,
        privateLanguageLessions: document.privateLanguageLessions,
        shelterAgency: document.shelterAgency,
        publicShower: document.publicShower,
        sportsClub: document.sportsClub,
        telecomunication: document.telecomunication,
        travel: document.travel,
        ukrainGovernmentAgency: document.ukrainGovernmentAgency,
        wc: document.wc
      },
      convenienceFilter: {
        wifi: document.wifi,
        free: document.free,
        lockers: document.lockers,
        cookedFood: document.cookedFood
      },
      personalFilter: {
        gender: document.gender,
        underAge: document.underAge
      },
      position: {
        lat: document.lat,
        lng: document.lng
      }
    };
  });
}
