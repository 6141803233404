import React from 'react';

type Props = {
  containerClass: string;
  indicatorClass: string;
};

/**
 * Returns the loading indicator as svg.
 */
const LoadingIcon = (props: Props): React.ReactElement => {
  const {containerClass, indicatorClass} = props;

  return (
    <svg
      className={containerClass}
      width="65px"
      height="65px"
      viewBox="0 0 66 66">
      <circle className={indicatorClass} cx="33" cy="33" r="28" />
    </svg>
  );
};

export default LoadingIcon;
