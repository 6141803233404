import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The full age icon
 */
const FullAgeIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('full-age-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="full-age icon">
      <title>FullAgeIcon</title>
      <path
        fill={fill}
        d="M7,21l5,1.77v2.46L4.41,22V19.91L12,16.7v2.46ZM22.82,26.4H20.28v-9.8l-3,.94v-2l5.3-1.9h.27ZM35.32,17a3,3,0,0,1-.47,1.65,3.35,3.35,0,0,1-1.28,1.15A3.72,3.72,0,0,1,35,21a3.17,3.17,0,0,1,.54,1.85,3.36,3.36,0,0,1-1.16,2.69,4.71,4.71,0,0,1-3.16,1,4.73,4.73,0,0,1-3.16-1,3.35,3.35,0,0,1-1.17-2.69A3.19,3.19,0,0,1,27.48,21,3.64,3.64,0,0,1,29,19.81a3.32,3.32,0,0,1-1.28-1.15A3,3,0,0,1,27.21,17,3.32,3.32,0,0,1,28.3,14.4a4.3,4.3,0,0,1,3-1,4.31,4.31,0,0,1,3,1A3.3,3.3,0,0,1,35.32,17ZM33,22.71a1.84,1.84,0,0,0-.48-1.34,1.93,1.93,0,0,0-2.59,0A2.08,2.08,0,0,0,30,24a2,2,0,0,0,2.61,0A1.83,1.83,0,0,0,33,22.71Zm-.26-5.58a1.76,1.76,0,0,0-.4-1.2,1.61,1.61,0,0,0-2.22,0,1.75,1.75,0,0,0-.4,1.21,1.84,1.84,0,0,0,.4,1.23,1.56,1.56,0,0,0,2.23,0A1.85,1.85,0,0,0,32.78,17.13Z"
      />
    </svg>
  );
};

export default FullAgeIcon;
