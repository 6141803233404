import { useDispatch, useSelector } from 'react-redux';
import React from 'react';

import * as styles from './styles.module.css';

import { getDictionary } from '../../store/locales';
import { removeCategory } from '../../store/filter';

import LanguageSelect from '../language-select/language-select';
import { useNavigate } from 'react-router';

type Props = {
  onMenuItemSelect: (arg0: void) => void;
};

/**
 * The menu
 */
function Menu(props: Props): React.ReactElement {
  const locales = useSelector(getDictionary);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onNavLinkClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault();

    dispatch(removeCategory());
    if (event.currentTarget.dataset.to) {
      navigate(event.currentTarget.dataset.to);
    }

    props.onMenuItemSelect();
  };

  return (
    <ul className={styles.navigation}>
      <li className={styles.navigationItem}>
        <a onClick={onNavLinkClick} href={'/about'} data-to="/about">
          {locales.aboutTitle}
        </a>
      </li>
      <li className={styles.navigationItem}>
        <a onClick={onNavLinkClick} href={'/imprint'} data-to="/imprint">
          {locales.imprintTitle}
        </a>
      </li>
      <li className={styles.navigationItem}>
        <a
          onClick={onNavLinkClick}
          href={'/tippsTricks'}
          data-to="/tippsTricks"
        >
          {locales.tippsTricksTitle}
        </a>
      </li>
      <li className={styles.navigationItem}>
        <a onClick={onNavLinkClick} href={'/datenschutz'} data-to="/privacy">
          {locales.privacyTitle}
        </a>
      </li>
      <li className={styles.navigationItem}>
        <LanguageSelect />
      </li>
    </ul>
  );
}

export default Menu;
