import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The default gender icon
 */
const DefaultGenderIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('default-gender-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="default-gender icon">
      <title>DefaultGenderIcon</title>
      <path
        fill={fill}
        d="M25.77,9.48H21.33V7H30v8.67H27.52V11.22L22.78,16a6.27,6.27,0,0,1,1,3.42,6.19,6.19,0,0,1-5,6.07V28h2.48v2.48H18.86V33H16.38V30.52H13.9V28h2.48v-2.6a6.19,6.19,0,0,1,1.24-12.26,6.13,6.13,0,0,1,3.4,1l4.74-4.74m-8.15,6.19a3.71,3.71,0,1,0,3.71,3.71A3.71,3.71,0,0,0,17.62,15.67Z"
      />
    </svg>
  );
};

export default DefaultGenderIcon;
