import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * The free service icon
 */
const FreeIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('free-service-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="free-service icon">
      <title>FreeIcon</title>
      <path
        fill={fill}
        d="M28.29,24.49v1.58l-.66-.87A6.78,6.78,0,0,0,28.29,24.49Zm-.46-7.33H21.54l1.52,2H27Zm-1.69,4H24.57l1,1.33Zm3,9.23h0l-1.63,1.23-2.16-2.85a9,9,0,0,1-11.08-5.62h-3.5l1-2h2l0-1,0-1h-3l1-2h2.5a9,9,0,0,1,.89-1.82l-4.4-5.81,1.53-1.16L29.06,30.47ZM16.46,17.16h.1l-.06-.07Zm-.6,4h3.73l-1.52-2H15.86l-.07,1ZM24,27l-2.94-3.88H16.46A6.93,6.93,0,0,0,24,27Zm-4.8-12.89a6.9,6.9,0,0,1,3.54-1,7,7,0,0,1,5.57,2.75l.86-2A9,9,0,0,0,18,12.53Z"
      />
    </svg>
  );
};

export default FreeIcon;
