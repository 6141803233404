/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * instagram icon
 */
const InstagramIcon = (props: Props): React.ReactElement => {
  const { fill, height, width, onClick, className = '' } = props;

  const iconClassNames = cx('instagram-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="-6 -6 36 36"
      aria-label="instagram icon"
    >
      <title>InstagramIcon</title>
      <circle cx="12" cy="12" r="18" />
      <path
        fill="#fff"
        d="M8.5,3.6h7c2.7,0,4.9,2.2,4.9,4.9v7c0,2.7-2.2,4.9-4.9,4.9h-7c-2.7,0-4.9-2.2-4.9-4.9v-7
	C3.6,5.8,5.8,3.6,8.5,3.6 M8.3,5.3c-1.7,0-3,1.4-3,3l0,0v7.4c0,1.7,1.4,3,3,3h7.4c1.7,0,3-1.4,3-3c0,0,0,0,0,0V8.3c0-1.7-1.4-3-3-3
	H8.3 M16.4,6.5c0.6,0,1,0.5,1,1s-0.5,1-1,1s-1-0.5-1-1S15.8,6.5,16.4,6.5 M12,7.8c2.3,0,4.2,1.9,4.2,4.2s-1.9,4.2-4.2,4.2
	S7.8,14.3,7.8,12S9.7,7.8,12,7.8 M12,9.5c-1.4,0-2.5,1.1-2.5,2.5s1.1,2.5,2.5,2.5s2.5-1.1,2.5-2.5S13.4,9.5,12,9.5z"
      />
    </svg>
  );
};

export default InstagramIcon;
