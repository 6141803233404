import React from 'react';
import cx from 'classnames';
import SuggestItem from './suggest-item';

import config from '../../config';

import * as styles from './styles.module.css';
import { GooglePlaceSuggest, Suggest } from '../../../typescript/poi';

type Props = {
  suggests: Array<Suggest | GooglePlaceSuggest>;
  isHidden: boolean;
  small: boolean;
  onSuggestSelect: (arg0: number) => void;
  withFillImages: boolean;
};

/**
 * The list with suggestions. Either from an API or provided as fixture
 */
const SuggestList = (props: Props): React.ReactElement => {
  /**
   * Render the view
   */
  const { suggests, onSuggestSelect, isHidden, small, withFillImages } = props;

  const classnames = cx(styles.list, {
    hidden: isHidden,
    [styles.listSmall]: small
  });

  return (
    <ul className={classnames}>
      {suggests.map((suggest, index) => {
        return (
          <SuggestItem
            key={suggest.id}
            suggest={suggest}
            onSelect={() => onSuggestSelect(index)}
          ></SuggestItem>
        );
      })}
    </ul>
  );
};

export default SuggestList;
