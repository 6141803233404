import React from 'react';

import LoadingSpinner from '../icons/loading-icon';

import * as styles from './styles.module.css';

type Props = {
  spinner?: boolean;
};

/**
 * An overlay showing a loading spinner.
 */
const LoadingOverlay = ({
  spinner = true
}: Props): React.ReactElement | null => {
  return (
    <div className={`${styles.loading} overlay`}>
      {spinner && (
        <LoadingSpinner
          containerClass={styles.spinner}
          indicatorClass={styles.path}
        />
      )}
    </div>
  );
};

export default LoadingOverlay;
