.container {
  position: relative;
  height: calc(100% - 114px);
  overflow: hidden;
}
.container *,
.container *::before,
.container *::after {
  box-sizing: initial;
}
@media (min-width: 875px) {
  .container {
    padding-left: 23.75em;
    height: calc(100% - 55px);
  }
}
.containerInner {
  position: relative;
  height: 100%;
}
.container--foodDrink {
  color: #84bd2f;
}
.container--sleep {
  color: #258cc4;
}
.container--hygiene {
  color: #087e87;
}
.container--medicalAid {
  color: #d33e41;
}
.container--informationAdvice {
  color: #ef9c16;
}
.container--other {
  color: #7a5ca5;
}
.mapCanvas {
  width: 100%;
  height: 100%;
}
.messageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: 100%;
}
.messageContainer:before {
  opacity: 0.3;
}
.notUsableMessage {
  color: var(--main-color);
  max-width: 300px;
  text-align: center;
}
.hidden {
  display: none;
}
