import config from '../config';

const FETCHING_LOCALES = 'locales/FETCHING_LOCALES';
export const LOCALES_FETCHED = 'locales/LOCALES_FETCHED';
const SET_CURRENT_LANG = 'locales/SET_CURRENT_LANG';

import {
  Action,
  Dispatch,
  LocalesState,
  StoreState,
  ThunkAction
} from '../../typescript/store';
import { Translation, Locale, Locales } from '../../typescript/locale';

import parseLocalesResponse from '../api/utils/locales-response-parser';
import { FALLBACK_LANGUAGE } from '../constants/language';

const initalState: LocalesState = {
  allLocales: {},
  language: getBrowserLanguage()
};

/**
 * Returns the store state after the reducer.
 * @return {Object}
 */
export default function reducer(
  state = initalState,
  action: Action
): LocalesState {
  switch (action.type) {
    case LOCALES_FETCHED:
      return {
        ...state,
        ...{
          allLocales: action.locales
        }
      };

    case SET_CURRENT_LANG:
      return {
        ...state,
        ...{
          language: action.lang
        }
      };
    default:
      return state;
  }
}

/**
 * Returns the fetching action.
 * @return {object}  The action
 */
function fetchingData() {
  return {
    type: FETCHING_LOCALES
  };
}

/**
 * Returns the data fetched action.
 */
function dataFetched(locales: Locales) {
  return {
    type: LOCALES_FETCHED,
    locales
  };
}

/**
 * Returns the set language action.
 */
export function setCurrentLang(lang: string): { type: string; lang: string } {
  localStorage.language = lang;

  document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';

  return {
    type: SET_CURRENT_LANG,
    lang
  };
}

/**
 * Fetches data from a worksheet. Validates some aspect
 * and returns a promise which resolves a proper object.
 * @return {Promise}
 */
export function fetchTranslations(): ThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(fetchingData());

    return fetch(config.localesUrl)
      .then((response) => {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        }

        return [];
      })
      .then((response) => {
        const translations = parseLocalesResponse(response.data);

        return translations.reduce((locales: Locales, item: Translation) => {
          Object.keys(item.languages).forEach((lang) => {
            if (!locales[lang]) {
              locales[lang] = {};
            }

            // @ts-ignore
            locales[lang][item.meta.id] = item.languages[lang];
          });

          return locales;
        }, {});
      })
      .then((data) => dispatch(dataFetched(data)))
      .catch(console.error);
  };
}

/**
 * Returns browser language.
 * @return {string}
 */
function getBrowserLanguage() {
  let lang = '';

  if (localStorage.language) {
    lang = localStorage.language;
  } else {
    if (navigator.languages) {
      // chrome does not currently set navigator.language
      // correctly https://code.google.com/p/chromium/issues/detail?id=101138
      // but it does set the first element of navigator.languages correctly
      lang = navigator.languages[0];
      // @ts-ignore just for IE
    } else if (navigator.userLanguage) {
      // @ts-ignore just for IE
      lang = navigator.userLanguage;
    } else {
      // as of this writing the latest version
      // of firefox + safari set this correctly
      lang = navigator.language;
    }

    lang = lang.slice(0, 2);
  }

  document.documentElement.dir = lang === 'ar' ? 'rtl' : 'ltr';

  return lang;
}

/**
 * Get the current dictionary
 */
export function getDictionary(store: StoreState): Locale {
  let dictionary = store.locales.allLocales[store.locales.language] || {};
  const fallbackDictionary = store.locales.allLocales[FALLBACK_LANGUAGE] || {};

  dictionary = new Proxy(dictionary, {
    get(target, phrase: string) {
      if (target[phrase]) {
        return target[phrase];
      }

      if (fallbackDictionary[phrase]) {
        return fallbackDictionary[phrase];
      }

      return `_${phrase}_`;
    }
  });

  return dictionary;
}

/**
 * Selector for the currently selected language
 */
export const currentLanguageSelector = (store: StoreState): string =>
  store.locales.language;

/**
 * Get all locales
 */
export function getAllLocales(store: StoreState): Locales {
  return store.locales.allLocales;
}
