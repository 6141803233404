/**
 * Checks for offline and online changes in connection.
 */
export function onConnectionChange(callback: (arg0: string) => void): void {
  // eslint-disable-next-line no-undefined
  if (navigator.onLine !== undefined && !navigator.onLine) {
    callback('offline');
  }

  window.addEventListener('offline', () => callback('offline'));
  window.addEventListener('online', () => callback('online'));
}
