import { useDispatch, useSelector } from 'react-redux';
import React, { useState } from 'react';
import cx from 'classnames';

import * as styles from './styles.module.css';

import {
  currentLanguageSelector,
  getAllLocales,
  getDictionary,
  setCurrentLang
} from '../../store/locales';
import { availableLanguages } from '../../constants/language';

/**
 * The component for the drop down language selection.
 */
const LanguageSelect = (): React.ReactElement => {
  const [collapsed, setCollapsed] = useState(false);
  const dispatch = useDispatch();

  const allLocales = useSelector(getAllLocales);
  const locale = useSelector(getDictionary);
  const currentLang = useSelector(currentLanguageSelector);

  /**
   * Toggles the drop down menu.
   */
  const toggleMenu = () => {
    setCollapsed(!collapsed);
  };

  /**
   * Actives the choosen language.
   */
  const activateChoice = (lang: string) => {
    dispatch(setCurrentLang(lang));
    toggleMenu();
  };

  const containerClasses = cx({
    [styles.selectContainer]: true,
    [styles.selectContainerCollapsed]: collapsed
  });
  const headerClasses = cx({
    [styles.header]: true,
    [styles.headerActivated]: !collapsed
  });

  return (
    <div className={styles.container}>
      <header className={headerClasses} onClick={() => toggleMenu()}>
        {locale.selectLanguage}
      </header>
      <ul className={containerClasses}>
        {availableLanguages.map((item: string) => (
          <li
            key={item}
            data-id={item}
            className={cx(
              styles.option,
              currentLang === item && styles.optionActive
            )}
            onClick={() => activateChoice(item)}
          >
            {allLocales[item].lang}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default LanguageSelect;
