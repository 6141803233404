import lodashFilter from 'lodash.filter';

import parsePoiResponse from '../api/utils/pois-response-parser';
import { getDirectionsUrl, getDistance } from '../api/google-maps';

import config from '../config';

import { REMOVE_CATEGORY } from './filter';

const FETCHING_DATA = 'pois/FETCHING_DATA';
export const DATA_FETCHED = 'pois/DATA_FETCHED';
export const ACTIVATE_POI = 'pois/ACTIVATE_POI';
const DEACTIVATE_POI = 'pois/DEACTIVATE_POI';
const GET_DISTANCE = 'pois/GET_DISTANCE';
const DISTANCE_RECEIVED = 'pois/DISTANCE_RECEIVED';
const DISTANCE_RECEIVED_ERROR = 'pois/DISTANCE_RECEIVED_ERROR';
const ADD_COMPARE_POI = 'pois/ADD_COMPARE_POI';
const REMOVE_COMPARE_POIS = 'pois/REMOVE_COMPARE_POIS';
const REMOVE_COMPARE_POI = 'pois/REMOVE_COMPARE_POI';

import {
  Action,
  ThunkAction,
  Dispatch,
  PoiState,
  StoreState
} from '../../typescript/store';
import { SubCategoryId, Poi, ParentCategoryId } from '../../typescript/poi';
import { categories } from '../constants/categories';

const initalState: PoiState = {
  allPois: [],
  comparePois: []
};

/**
 * Returns the store state after the reducer.
 */
export default function reducer(
  state: PoiState = initalState,
  action: Action
): PoiState {
  switch (action.type) {
    case DATA_FETCHED:
      return {
        ...state,
        ...{ allPois: action.data }
      };
    case ACTIVATE_POI:
      return {
        ...state,
        ...{
          activePoi: action.poi
        }
      };

    case ADD_COMPARE_POI:
      return {
        ...state,
        comparePois: [...state.comparePois, action.poi].splice(0, 3)
      };

    case REMOVE_COMPARE_POI:
      return {
        ...state,
        comparePois: [
          ...state.comparePois.filter(
            (poi: Poi, index: number) => index !== action.index
          )
        ]
      };

    case REMOVE_COMPARE_POIS:
      return {
        ...state,
        comparePois: []
      };

    case DISTANCE_RECEIVED:
      return { ...state, ...{ distance: action.distance.text } };

    case DEACTIVATE_POI:
      delete state.activePoi;
      delete state.distance;
      return { ...state };

    case REMOVE_CATEGORY:
      delete state.activePoi;
      delete state.distance;
      return { ...state };
    default:
      return state;
  }
}

/**
 * Returns the activate poi action.
 */
export function addComparePoi(poi: Poi): Action {
  return {
    type: ADD_COMPARE_POI,
    poi
  };
}

/**
 * Returns the activate poi action.
 */
export function removeComparePoi(index: number): Action {
  return {
    type: REMOVE_COMPARE_POI,
    index
  };
}

/**
 * Returns the activate poi action.
 */
export function removeComparePois(): Action {
  return {
    type: REMOVE_COMPARE_POIS
  };
}

/**
 * Returns the activate poi action.
 */
export function activatePoi(slug: string): Action {
  return {
    type: ACTIVATE_POI,
    poi: slug
  };
}

/**
 * Returns the deactivate poi action.
 */
export function deactivatePoi(): Action {
  return {
    type: DEACTIVATE_POI
  };
}

/**
 * Returns the fetching action.
 */
function fetchingData(): Action {
  return {
    type: FETCHING_DATA
  };
}

/**
 * Returns the data fetched action.
 */
function dataFetched(data: Array<Poi>): Action {
  return {
    type: DATA_FETCHED,
    data
  };
}

/**
 * To have a approximate distance and a link to the map we call the maps api.
 * Only when we have a valid poi and a current user position.
 */
export function getPoiDistance(poi: Poi): ThunkAction | null {
  return (dispatch: Dispatch, getStore) => {
    const { position } = getStore().app;
    if (!position || !poi) {
      return null;
    }

    dispatch({
      type: GET_DISTANCE
    });

    poi.directionsUrl = getDirectionsUrl(position, poi.position);

    return getDistance(position, poi.position)
      .then((distance) =>
        dispatch({
          type: DISTANCE_RECEIVED,
          distance
        })
      )
      .catch((error) =>
        dispatch({
          type: DISTANCE_RECEIVED_ERROR,
          error
        })
      );
  };
}

/**
 * Fetches data from a worksheet. Validates some aspect
 * and returns a promise which resolves a proper object.
 */
export function fetchPois(): ThunkAction {
  return (dispatch: Dispatch) => {
    dispatch(fetchingData());

    return fetch(config.dataUrl)
      .then((response) => {
        const contentType = response.headers.get('content-type');
        if (contentType && contentType.indexOf('application/json') !== -1) {
          return response.json();
        }

        return [];
      })
      .then((response) => {
        const pois = parsePoiResponse(response.data);
        return pois.filter(isValidPoi).map(modifyPoiProperties);
      })
      .then((pois) => dispatch(dataFetched(pois)))
      .catch(console.error);
  };
}

/**
 * Returns if the pois is valid or not.
 */
function isValidPoi(poi: Poi) {
  const poiCategories = (
    Object.keys(poi.category) as Array<SubCategoryId>
  ).filter((category) => poi.category[category]);
  const categoryIds = categories.map<SubCategoryId>(({ id }) => id);
  const hasValidCategories = poiCategories.every((categoryId) =>
    categoryIds.includes(categoryId)
  );

  return Boolean(
    // poi.meta &&
    // poi.meta.public &&
    poi.position && poi.position.lat && poi.position.lng && hasValidCategories
  );
}

/**
 * Returns a modified poi.
 */
function modifyPoiProperties(poi: Poi, index: number) {
  const parentCategories = (Object.keys(poi.category) as Array<SubCategoryId>)
    .filter((category) => poi.category[category])
    .map(getParentCategory);

  parentCategories.forEach((category) => {
    if (category) {
      // @ts-ignore implicitly any
      poi.category[category] = true;
    }
  });

  poi.id = index;

  return poi;
}

/**
 * Returns the parent category for a category id.
 * If no parent category is found the current catergory is returned as ParentCategoryId.
 */
function getParentCategory(categoryId: SubCategoryId): ParentCategoryId {
  const subCategory = categories.find((cat) => cat.id === categoryId);

  if (!subCategory?.parent) {
    return categoryId as unknown as ParentCategoryId;
  }

  return subCategory.parent;
}

/**
 * Returns a filtered array of all pois.
 */
export function filterPois(state: StoreState): Poi[] {
  const {
    category,
    convenienceFilter = {},
    personalFilter = {}
  } = state.filter;
  const pois = Object.values(state.pois.allPois);

  if (!category) {
    return pois;
  }

  return lodashFilter(pois, {
    category: {
      [category]: true
    },
    convenienceFilter,
    personalFilter
  });
}

/**
 * Returns the active poi data.
 */
export function getActivePoi(state: StoreState): Poi | null {
  const { allPois: pois, activePoi } = state.pois;

  if (!pois || !activePoi) {
    return null;
  }

  return Object.values(pois).find((poi) => poi.meta.slug === activePoi) || null;
}
