import React from 'react';

import MaleIcon from '../icons/male-icon';
import FemaleIcon from '../icons/female-icon';
import DefaultGenderIcon from '../icons/default-gender-icon';
import UnderAgeIcon from '../icons/under-age-icon';
import FullAgeIcon from '../icons/full-age-icon';
import DefaultAgeIcon from '../icons/default-age-icon';

import * as styles from './styles.module.css';
type Props = {
  onFilterChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  gender?: string;
  underAge?: string;
};
/**
 * Returns the component for the personal filters.
 */
const PersonalFilters = (props: Props): React.ReactElement => {
  const { onFilterChange, gender, underAge } = props;

  return (
    <div className={styles.personalFilterContainer}>
      <div className={styles.filterSection}>
        <div className={styles.sectionContent}>
          <input
            onChange={onFilterChange}
            type="radio"
            name="gender"
            value="male"
            checked={gender === 'male'}
            id="gender-selector-male"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="gender-selector-male"
          >
            <MaleIcon width={30} height={30} />
          </label>

          <input
            onChange={onFilterChange}
            type="radio"
            name="gender"
            value="female"
            checked={gender === 'female'}
            id="gender-selector-female"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="gender-selector-female"
          >
            <FemaleIcon width={30} height={30} />
          </label>

          <input
            onChange={onFilterChange}
            type="radio"
            name="gender"
            value="none"
            checked={!gender}
            id="gender-selector-none"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="gender-selector-none"
          >
            <DefaultGenderIcon width={30} height={30} />
          </label>
        </div>
      </div>

      <div className={styles.filterSection}>
        <div className={styles.sectionContent}>
          <input
            onChange={onFilterChange}
            type="radio"
            name="underAge"
            value="underAge"
            checked={underAge === 'underAge'}
            id="age-selector-underage"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="age-selector-underage"
          >
            <UnderAgeIcon width={30} height={30} />
          </label>
          <input
            onChange={onFilterChange}
            type="radio"
            name="underAge"
            value="fullAge"
            checked={underAge === 'fullAge'}
            id="age-selector-fullage"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="age-selector-fullage"
          >
            <FullAgeIcon width={30} height={30} />
          </label>
          <input
            onChange={onFilterChange}
            type="radio"
            name="underAge"
            checked={!underAge}
            value="none"
            id="age-selector-none"
          />
          <label
            className={styles.labelPersonalFilter}
            htmlFor="age-selector-none"
          >
            <DefaultAgeIcon width={30} height={30} />
          </label>
        </div>
      </div>
    </div>
  );
};
export default PersonalFilters;
