import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The female icon
 */
const FemaleIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('female-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="female icon">
      <title>FemaleIcon</title>
      <path
        fill={fill}
        d="M20,6a8,8,0,0,1,1.33,15.89v5.77H25v2.67H21.33V34H18.67V30.33H15V27.67h3.67V21.89A8,8,0,0,1,20,6m0,2.67A5.33,5.33,0,1,0,25.33,14,5.33,5.33,0,0,0,20,8.67Z"
      />
    </svg>
  );
};

export default FemaleIcon;
