import React from 'react';
import { useSelector } from 'react-redux';

import WifiIcon from '../icons/wifi-icon';
import FreeIcon from '../icons/free-icon';
import CookedFoodIcon from '../icons/cooked-food-icon';
import LockerIcon from '../icons/locker-icon';
import DogsIcon from '../icons/dogs-icon';
import { getCategoryFilters } from '../../store/filter';

import * as styles from './styles.module.css';

const components = {
  wifi: WifiIcon,
  free: FreeIcon,
  cookedFood: CookedFoodIcon,
  lockers: LockerIcon,
  dogs: DogsIcon
};

const renderFilter = (
  filter: string,
  checked: boolean,
  onFilterChange: () => void
): React.ReactElement => {
  // @ts-ignore
  const Icon = components[filter];

  return (
    <div>
      <input
        onChange={onFilterChange}
        type="checkbox"
        name={filter}
        value={filter}
        checked={checked}
        id={`convenience-filter-${filter}`}
      />

      <label className={styles.label} htmlFor={`convenience-filter-${filter}`}>
        <Icon width={30} height={30} />
      </label>
    </div>
  );
};

type Props = {
  hidden?: boolean;
  onFilterChange: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};
/**
 * Returns the component for the convenience filters.
 */
const ConvenienceFilters = (props: Props): React.ReactElement | null => {
  const categoryFilter = useSelector(getCategoryFilters);
  const { onFilterChange, hidden } = props;

  if (!categoryFilter) {
    return null;
  }

  return (
    <div className={hidden ? styles.hidden : styles.filterSection}>
      <div className={styles.sectionContent}>
        {categoryFilter.map((filter) =>
          // @ts-ignore
          renderFilter(filter, props[filter], onFilterChange)
        )}
      </div>
    </div>
  );
};

export default ConvenienceFilters;
