/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The under 18 icon
 */
const UnderAgeIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('under-age-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="under-age icon">
      <title>UnderAgeIcon</title>
      <path
        fill={fill}
        d="M9.74,20.94,4.63,19.16V16.71l7.66,3.21V22L4.63,25.2V22.75ZM22.6,26.4H20.06v-9.8l-3,.94v-2l5.3-1.9h.27ZM35.1,17a3,3,0,0,1-.47,1.65,3.35,3.35,0,0,1-1.28,1.15A3.72,3.72,0,0,1,34.82,21a3.17,3.17,0,0,1,.54,1.85,3.36,3.36,0,0,1-1.16,2.69,4.71,4.71,0,0,1-3.16,1,4.73,4.73,0,0,1-3.16-1,3.35,3.35,0,0,1-1.17-2.69A3.19,3.19,0,0,1,27.27,21a3.64,3.64,0,0,1,1.47-1.23,3.32,3.32,0,0,1-1.28-1.15A3,3,0,0,1,27,17a3.32,3.32,0,0,1,1.09-2.61,4.3,4.3,0,0,1,3-1,4.31,4.31,0,0,1,3,1A3.3,3.3,0,0,1,35.1,17Zm-2.28,5.7a1.84,1.84,0,0,0-.48-1.34,1.93,1.93,0,0,0-2.59,0,2.08,2.08,0,0,0,0,2.66,2,2,0,0,0,2.61,0A1.83,1.83,0,0,0,32.82,22.71Zm-.26-5.58a1.76,1.76,0,0,0-.4-1.2,1.61,1.61,0,0,0-2.22,0,1.75,1.75,0,0,0-.4,1.21,1.84,1.84,0,0,0,.4,1.23,1.56,1.56,0,0,0,2.23,0A1.85,1.85,0,0,0,32.56,17.13Z"
      />
    </svg>
  );
};

export default UnderAgeIcon;
