import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import ShareLink from '../share-link/share-link';

import { getDictionary } from '../../store/locales';
import { isAppOffline } from '../../store/app';

import * as styles from './styles.module.css';
import config from '../../config';
import { Poi } from '../../../typescript/poi';
import { StoreState } from '../../../typescript/store';

type Props = {
  open: boolean;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
  poi: Poi;
};

/**
 * Share component for infowindow
 */
const Share = ({ open, onClick, poi }: Props): React.ReactElement => {
  const locales = useSelector((state: StoreState) => getDictionary(state));
  const isOffline = useSelector((state: StoreState) => isAppOffline(state));

  const classes = cx(styles.share, {
    [styles.open]: open,
    [styles.shareOffline]: isOffline
  });
  const close = (event: React.MouseEvent<HTMLElement>) => {
    // @ts-ignore
    if (event.target.classList.contains(styles.share)) {
      onClick(event);
    }
  };
  const whatsappText = `\r\n${locales.poiShareWhatsapp}
    ${poi.address.name.trim()} ${window.location.href}`;
  const mailText = `${locales.poiShareMailCopy}

"${poi.address.name.trim()}"
${window.location.href}`;

  return (
    <section className={classes} onClick={close}>
      <ShareLink
        className={styles.shareLink}
        network="facebook"
        url={window.location.href}
      />
      <ShareLink
        className={styles.shareLink}
        network="whatsapp"
        text={whatsappText}
      />
      <ShareLink
        className={styles.shareLink}
        network="instagram"
        text={locales.poiShareInstagram}
      />
      <ShareLink
        className={styles.shareLink}
        network="mail"
        recipient={config.contactEmail}
        subject={locales.poiShareMailSubject}
        body={mailText}
      />
    </section>
  );
};

export default Share;
