import { useSelector } from 'react-redux';
import React, { useState } from 'react';
import cx from 'classnames';

import { isAppOffline } from '../../store/app';
import { getActivePoi } from '../../store/pois';
import { getDictionary } from '../../store/locales';
import getMailTemplate from '../../lib/mail-template';
import { getDirectionsUrl } from '../../api/google-maps';

import Link from '../link/link';
import Header from './header';
import Share from './share';

import HomeIcon from '../icons/home-icon';
import InfoIcon from '../icons/info-icon';
import TimeIcon from '../icons/time-icon';
import DirectionsIcon from '../icons/directions-icon';
import EmailIcon from '../icons/email-icon';
import PhoneIcon from '../icons/phone-icon';
import LinkIcon from '../icons/link-icon';

import * as styles from './styles.module.css';
import { StoreState } from '../../../typescript/store';
import { useNavigate } from 'react-router';
import ReactMarkdown from 'react-markdown';

/**
 * Renders the infowindow component.
 */
const Infowindow = (): React.ReactElement | null => {
  const [open, setOpen] = useState(false);
  const [openShare, setOpenShare] = useState(false);
  const navigate = useNavigate();

  const poi = useSelector((state: StoreState) => getActivePoi(state));
  const distance = useSelector((state: StoreState) => state.pois.distance);
  const locales = useSelector((state: StoreState) => getDictionary(state));
  const position = useSelector((state: StoreState) => state.app.position);
  const isOffline = useSelector((state: StoreState) => isAppOffline(state));
  const category = useSelector((state: StoreState) => state.filter.category);

  /**
   * Handles the toggle action and sets the state accordingly.
   */
  const toggle = () => {
    setOpen(!open);
  };

  /**
   * Handles the toggle action and sets the state accordingly.
   */
  const toggleShare = () => {
    setOpenShare(!openShare);
  };

  const deactivatePoi = () => {
    navigate(`/category/${category}/`);
  };

  if (!poi) {
    return null;
  }

  const containerClasses = cx(styles.container, 'overlay', {
    [styles.hidden]: !poi.address.name
  });
  const contentClasses = cx(styles.content, {
    [styles.hidden]: !open && !window.matchMedia('(min-width: 875px)').matches
  });
  const contentItemClasses = styles.contentItem;
  const directionsUrl = getDirectionsUrl(position, poi.position);

  return (
    <div className={containerClasses}>
      <Header
        onClick={toggle}
        onShareClick={toggleShare}
        onCloseClick={deactivatePoi}
        distance={distance}
        poi={poi}
        open={open}
        isOffline={isOffline}
      />

      <section className={contentClasses}>
        <div className={contentItemClasses}>
          <HomeIcon className={styles.icon} width={22} height={22} />
          <p>
            {`${poi.address.street} ${poi.address.streetnumber}`}
            <br />
            {`${poi.address.zip} ${poi.address.city}`}
          </p>

          <a
            className={styles.iconDirections}
            href={directionsUrl}
            target="_blank"
            rel="noopener noreferrer"
          >
            <DirectionsIcon width={22} height={22} />
          </a>
        </div>

        {poi.address.notes && (
          <div className={contentItemClasses}>
            <InfoIcon className={styles.icon} width={22} height={22} />
            <p>{poi.address.notes}</p>
          </div>
        )}

        {poi.address.openingHours && (
          <div className={contentItemClasses}>
            <TimeIcon className={styles.icon} width={22} height={22} />
            <div>
              <ReactMarkdown>{poi.address.openingHours || ''}</ReactMarkdown>
            </div>
          </div>
        )}

        {poi.address.phone && (
          <div className={contentItemClasses}>
            <PhoneIcon className={styles.icon} width={22} height={22} />
            <p>{poi.address.phone}</p>
          </div>
        )}

        {poi.address.email && (
          <div className={contentItemClasses}>
            <EmailIcon className={styles.icon} width={22} height={22} />
            <p>{poi.address.email}</p>
          </div>
        )}

        {poi.address.links && (
          <div className={contentItemClasses}>
            <LinkIcon className={styles.icon} width={22} height={22} />
            <p>
              <a
                href={poi.address.links}
                target="_blank"
                rel="noopener noreferrer"
              >
                {poi.address.links}
              </a>
            </p>
          </div>
        )}

        <Link
          disabled={isOffline}
          className={cx(styles.button, styles.directionsButton)}
          to={directionsUrl}
          text={locales.showRoute}
          iconName="directions"
        />

        <Link
          disabled={isOffline}
          target="_self"
          className={styles.button}
          to={getMailTemplate(poi)}
          text={locales.submitCorrection}
          iconName="edit"
        />
      </section>
      <Share poi={poi} open={openShare} onClick={toggleShare} />
    </div>
  );
};

export default Infowindow;
