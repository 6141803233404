import React from 'react';

import MyLocationIcon from '../icons/my-location-icon';
import LoadingSpinner from '../icons/loading-icon';

import * as styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { StoreState } from '../../../typescript/store';

type Props = {
  requestLocation: () => void;
};
/**
 * Returns the location icon.
 */
const RequestLocationButton = ({
  requestLocation
}: Props): React.ReactElement => {
  const requestingLocation = useSelector(
    (state: StoreState) => state.app.requestingLocation
  );
  return (
    <div className={styles.locationIconContainer}>
      {requestingLocation ? (
        <LoadingSpinner
          containerClass={styles.spinner}
          indicatorClass={styles.path}
        />
      ) : (
        <MyLocationIcon
          className={styles.requestLocationIcon}
          onClick={requestLocation}
          width={32}
          height={32}
        />
      )}
    </div>
  );
};

export default RequestLocationButton;
