import React from 'react';

import * as styles from './styles.module.css';

import clsx from 'classnames';

interface Props {
  checked: boolean;
  onChange: () => void;
}

const ToggleSwitch = ({ checked, onChange }: Props): React.ReactElement => (
  <label className={styles.toggleSwitch}>
    <input type="checkbox" checked={checked} onChange={() => onChange()} />
    <span className={clsx(styles.round, styles.slider)}></span>
  </label>
);

export default ToggleSwitch;
