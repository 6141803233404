.container {
  display: flex;
  justify-content: center;
}
.containerOffline {
  pointer-events: none;
}
.containerOffline .shareIcon {
  color: var(--main-color);
  filter: brightness(70);
}
.shareIcon {
  color: var(--main-color);
  width: 2.25em;
  height: 2.25em;
  margin: 0.9375em;
}
@media (min-width: 580px) {
  .shareIcon {
    margin: 1.5em;
  }
}
.hidden {
  display: none;
}
