import React, { FunctionComponent, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { useSelector } from 'react-redux';

import { getCookiePreferences } from '../../store/app';

import CookieSettings from './cookie-settings';

import * as styles from './styles.module.css';

const disclaimerRoot = document.querySelector('.disclaimer-root');
if (disclaimerRoot) {
  disclaimerRoot.classList.add(styles.disclaimerRoot);
}

const CookieDisclaimer: FunctionComponent = () => {
  const cookiePreferences = useSelector(getCookiePreferences);

  if (cookiePreferences || !disclaimerRoot) {
    return null;
  }

  useEffect(() => {
    disclaimerRoot.classList.add(styles.disclaimerRootVisible);
    return () => disclaimerRoot.classList.remove(styles.disclaimerRootVisible);
  });

  return ReactDOM.createPortal(
    <div className={styles.container}>
      <div className={styles.content}>
        <CookieSettings />
      </div>
    </div>,
    disclaimerRoot
  );
};

export default CookieDisclaimer;
