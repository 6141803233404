.container {
  font-size: 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}
@media (min-width: 875px) {
  .container {
    height: 100%;
  }
}
.containerFullscreen {
  position: absolute;
  top: 3.4375em;
  z-index: 1;
  background: #fff;
  left: 0;
  right: 0;
  bottom: 0;
}
.header {
  position: relative;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  padding: 0.6875em 3.125em 0.5em 1em;
  box-shadow: var(--box-shadow);
  z-index: 1;
}
.header:before {
  opacity: 0.3;
}
.list {
  margin: 0;
  background: #fff;
  overflow-y: auto;
  padding: 0 0 0 0.9375em;
  list-style: none;
}
@media (max-width: 875px) {
  .listSmall {
    position: absolute;
    top: 3.4375em;
    left: 44px;
    right: 44px;
    max-height: 300px;
    z-index: 1;
  }
}
@media (min-width: 875px) {
  .list {
    display: block;
  }
}
.listIcon,
.mapIcon {
  flex-shrink: 0;
  height: 1.125em;
  width: 1.125em;
  margin-right: 0.75em;
}
@media (min-width: 875px) {
  .listIcon,
  .mapIcon {
    display: none;
  }
}
.filterIcon {
  height: 1.125em;
  width: 1.125em;
  margin-right: 0.75em;
}
.listItem {
  padding: 0.5em 0 0.5em 0.5em;
  border-top: 1px solid var(--main-color);
  min-height: 4.0625em;
  color: var(--main-color);
  cursor: pointer;
}
.listItemAddress {
  color: #808080;
}

.listItemActive .listItemAddress {
  color: var(--main-color);
}
.input {
  box-sizing: border-box;
  width: 100%;
  padding: 0.625em 1.875em 0.625em 0.625em;
  border: none;
  transition: border 0.2s, box-shadow 0.2s;
  border-radius: 2px;
}
.input:focus {
  outline: none;
  box-shadow: var(--box-shadow-inset);
}
.listImage {
  max-width: 100%;
  border-top: 1px solid var(--main-color);
  padding: 0.625em 0.625em 0.625em 0;
  box-sizing: border-box;
  display: block;
  object-fit: cover;
}
