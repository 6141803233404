import {DATA_FETCHED} from './pois';

import {LOCALES_FETCHED} from './locales';

const TOGGLE_CONNECTION_STATE = 'app/TOGGLE_CONNECTION_STATE';
const TOGGLE_COMPARE_MODE = 'app/TOGGLE_COMPARE_MODE';
const DISABLE_COMPARE_MODE = 'app/DISABLE_COMPARE_MODE';
const TOGGLE_MENU = 'app/TOGGLE_MENU';
const SET_CENTER = 'app/SET_CENTER';
const REQUEST_LOCATION = 'app/REQUEST_LOCATION';
const LOCATION_REQUESTED = 'app/LOCATION_REQUESTED';
const MAPS_LOADED = 'app/MAPS_LOADED';
const SET_COOKIE_PREFERENCES = 'app/SET_COOKIE_PREFERENCES';

import config from '../config';
import {geolocate} from '../api/location';
import cookieUtils, {CookiePreferences} from '../lib/cookie-preferences';

import {AppState, Action, Dispatch, StoreState} from '../../typescript/store';

const initalState: AppState = {
  mapCenter: config.defaultMapCenter,
  connectionState: 'online',
  isCompareMode: false,
  requestingLocation: false,
  poisFetched: false,
  mapsLoaded: false,
  cookiePreferences: cookieUtils.getCookiePreferences()
};

/**
 * Returns the store state after the reducer.
 * @param  {Object} [state=initalState] The current state of the store.
 * @param  {Object} action              The action.
 * @return {Object}
 */
export default function reducer(
  state: AppState = initalState,
  action: Action
): AppState {
  switch (action.type) {
    case DATA_FETCHED:
      return {...state, ...{poisFetched: true}};

    case LOCALES_FETCHED:
      return {...state, ...{localesFetched: true}};

    case TOGGLE_COMPARE_MODE:
      state.isCompareMode = !state.isCompareMode;
      return {...state};

    case DISABLE_COMPARE_MODE:
      return {...state, ...{isCompareMode: false}};

    case SET_CENTER:
      return {
        ...state,
        ...{
          mapCenter: action.center
        }
      };

    case REQUEST_LOCATION:
      return {
        ...state,
        ...{
          requestingLocation: true
        }
      };

    case LOCATION_REQUESTED: {
      return {
        ...state,
        ...{
          requestingLocation: false,
          position: action.location
        }
      };
    }

    case TOGGLE_CONNECTION_STATE: {
      return {
        ...state,
        connectionState: action.state
      };
    }

    case MAPS_LOADED: {
      return {
        ...state,
        autocompleteService: new window.google.maps.places.AutocompleteService(),
        geocoder: new window.google.maps.Geocoder(),
        mapsLoaded: true
      };
    }

    case SET_COOKIE_PREFERENCES: {
      cookieUtils.setCookiePreferences(action.cookiePrefs);
      return {
        ...state,
        cookiePreferences: action.cookiePrefs
      };
    }

    default:
      return state;
  }
}

/**
 * Returns the toggle menu action.
 */
export function toggleConnectionState(state: string): Action {
  return {
    type: TOGGLE_CONNECTION_STATE,
    state
  };
}

/**
 * Returns the toggle compare mode action.
 */
export function toggleCompareMode(): Action {
  return {
    type: TOGGLE_COMPARE_MODE
  };
}

/**
 * Returns the toggle compare mode action.
 */
export function disableCompareMode(): Action {
  return {
    type: DISABLE_COMPARE_MODE
  };
}

/**
 * Returns the toggle menu action.
 * @return {Object}
 */
export function toggleMenu(): Action {
  return {
    type: TOGGLE_MENU
  };
}

/**
 * Returns the toggle menu action.
 * @return {Object}
 */
export function showCategories(): Action {
  return {
    type: TOGGLE_MENU
  };
}

/**
 * Returns the toggle menu action.
 * @param {Object} center The new center
 * @return {Object}
 */
export function setCenter(center: {lat: number; lng: number}): Action {
  return {
    type: SET_CENTER,
    center
  };
}

/**
 * Returns the action to request the geolocation.
 * @return {Object}
 */
function requestGeoposition() {
  return {
    type: REQUEST_LOCATION
  };
}

/**
 * Returns location requested action
 * @param  {Object} location The location.
 * @return {Object}
 */
function locationRequested(location: {lat: number; lng: number}) {
  return {
    type: LOCATION_REQUESTED,
    location
  };
}

/**
 * Returns the action to set cookie preferences.
 * @param {CookiePreferences} cookiePrefs The cookie preferences
 * @return {Object}
 */
export function setCookiePreferences(cookiePrefs: CookiePreferences): Action {
  return {
    type: SET_COOKIE_PREFERENCES,
    cookiePrefs
  };
}

/**
 * Returns the action to set maps api state to loaded.
 * @return {Object}
 */
export function setMapsLoaded(): Action {
  return {
    type: MAPS_LOADED
  };
}

/**
 * Returns the action creator to request a current location.
 * @return {Function}
 */
export function requestLocation() {
  return (dispatch: Dispatch): void => {
    dispatch(requestGeoposition());

    geolocate()
      .then(location => {
        if (typeof location === 'object' && location !== null) {
          dispatch(
            locationRequested({
              lat: location.coords.latitude,
              lng: location.coords.longitude
            })
          );
        }
      })
      .catch(console.error);
  };
}

/**
 * Offline selector
 */
export function isAppOffline(state: StoreState): boolean {
  return state.app.connectionState === 'offline';
}

/**
 * Maps API loaded selector
 */
export function isMapsLoaded(state: StoreState): boolean {
  return state.app.mapsLoaded;
}

/**
 * Cookie Preferences Selector
 */
export function getCookiePreferences(
  state: StoreState
): CookiePreferences | null {
  return state.app.cookiePreferences;
}
