import React from 'react';
import cx from 'classnames';

import * as styles from './styles.module.css';

import ShareIcon from '../icons/share-icon';
import DownIcon from '../icons/down-icon';
import CloseIcon from '../icons/close-icon';
import CookedFoodIcon from '../icons/cooked-food-icon';
import DefaultAgeIcon from '../icons/default-age-icon';
import DefaultGenderIcon from '../icons/default-gender-icon';
import DogsIcon from '../icons/dogs-icon';
import MaleIcon from '../icons/male-icon';
import FemaleIcon from '../icons/female-icon';
import UnderAgeIcon from '../icons/under-age-icon';
import FullAgeIcon from '../icons/full-age-icon';
import FreeIcon from '../icons/free-icon';
import LockerIcon from '../icons/locker-icon';
import WifiIcon from '../icons/wifi-icon';
import { Poi } from '../../../typescript/poi';

const filterIcons = {
  cookedFood: CookedFoodIcon,
  defaultAge: DefaultAgeIcon,
  defaultGender: DefaultGenderIcon,
  dogs: DogsIcon,
  male: MaleIcon,
  female: FemaleIcon,
  underAge: UnderAgeIcon,
  fullAge: FullAgeIcon,
  free: FreeIcon,
  locker: LockerIcon,
  wifi: WifiIcon
};

type Props = {
  distance?: number;
  onClick: () => void;
  onShareClick: () => void;
  onCloseClick?: () => void;
  poi: Poi;
  open: boolean;
  isOffline: boolean;
};

/**
 * The informational header for a infowindow.
 */
function Header({
  onClick,
  onShareClick,
  onCloseClick,
  distance,
  poi,
  open,
  isOffline
}: Props): React.ReactElement {
  const toggleClasses = cx(styles.toggle, {
    [styles.toggleOpen]: open
  });
  const titleClasses = cx(styles.title, 'overlay');
  const filters = Object.keys(poi.convenienceFilter).filter(
    // @ts-ignore
    (filter) => poi.convenienceFilter[filter]
  );

  return (
    <header onClick={onClick} className={styles.header}>
      <div className={titleClasses}>
        <div className={styles.name}>{poi.address.name}</div>

        {distance && <div className={styles.distance}>{distance}</div>}

        <div className={toggleClasses}>
          <DownIcon width={30} height={30} />
        </div>

        <div className={styles.close} onClick={onCloseClick}>
          <CloseIcon width={30} height={30} />
        </div>
      </div>

      <section className={styles.filter}>
        {filters.map((filter) => renderFilterIcon(filter))}
        {!isOffline && (
          <ShareIcon onClick={onShareClick} className={styles.iconShare} />
        )}
      </section>
    </header>
  );
}

/**
 * Returns the icon component for a given filter type.
 */
function renderFilterIcon(filter: string) {
  // @ts-ignore
  const Icon = filterIcons[filter];

  return <Icon key={filter} width={25} height={25} className={styles.icon} />;
}

export default Header;
