.disclaimerRoot {
  display: none;
}
.disclaimerRootVisible {
  display: initial;
  z-index: 40;
}
.container {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  overflow: hidden;
  z-index: 40;
  max-width: 31.25em;
}
.content {
  border-radius: 0.25em;
  margin: 0.625em;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  overflow: hidden;
  padding: 0.9375em;
}
.settingsContainer {
  display: flex;
  flex-direction: column;
}
.buttons {
  display: flex;
  align-self: flex-end;
  flex-direction: column;
  justify-content: end;
  margin-top: 0.9375em;
}
.buttonAccept {
  align-self: flex-end;
  color: #e6007d;
}
.buttonSettings {
  align-self: flex-end;
  width: 100%;
  margin-bottom: 0.9375em;
  color: #ccc;
}
.settingsIcon {
  fill: #ccc;
}
.settingsSwitch {
  display: flex;
  padding: 0.3125em 0.625em;
  margin-bottom: 0.9375em;
  justify-content: space-between;
}
