import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { removeComparePoi } from '../../store/pois';
import { getDictionary } from '../../store/locales';

import CompareInfo from './compare-info';
import Link from '../link/link';

import * as styles from './styles.module.css';
import { StoreState } from '../../../typescript/store';

/**
 * Compare component
 */
const Compare = (): React.ReactElement | null => {
  const pois = useSelector((state: StoreState) => state.pois.comparePois);
  const dispatch = useDispatch();

  const isCompareMode = useSelector(
    (state: StoreState) => state.app.isCompareMode
  );
  const locales = useSelector(getDictionary);

  if (!isCompareMode) {
    return null;
  }

  const containerClasses = `${styles.container} overlay`;

  return (
    <div className={containerClasses}>
      <CompareInfo
        poi={pois[0]}
        onClick={() => dispatch(removeComparePoi(0))}
      />
      <CompareInfo
        poi={pois[1]}
        onClick={() => dispatch(removeComparePoi(1))}
      />
      <CompareInfo
        poi={pois[2]}
        onClick={() => dispatch(removeComparePoi(2))}
      />
      <Link
        onClick={() => window.print()}
        text={locales.print}
        iconName="print"
        className={styles.printButton}
      />
    </div>
  );
};

export default Compare;
