import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick?: () => void;
  className: string;
};

/**
 * The check/done icon from material ui
 */
const CheckIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('check-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="check icon">
      <title>checkIcon</title>
      <path
        fill={fill}
        d="M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z"
      />
    </svg>
  );
};

export default CheckIcon;
