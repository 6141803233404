import React, { useRef } from 'react';
import cx from 'classnames';
import { GooglePlaceSuggest, Suggest } from '../../../typescript/poi';

import * as styles from './styles.module.css';

type Props = {
  suggest: Suggest | GooglePlaceSuggest;
  onSelect: (arg0: Suggest | GooglePlaceSuggest) => void;
};

/**
 * A single GooglePlaceSuggest item in the list
 */
const SuggestItem = ({ suggest, onSelect }: Props): React.ReactElement => {
  const li = useRef(null);

  /**
   * When the suggest item got clicked
   */
  const onClick = (event: React.MouseEvent<HTMLLIElement>) => {
    event.preventDefault();
    onSelect(suggest);
  };

  /**
   * Render the view
   */
  const className = cx(styles.listItem);

  return (
    <li className={className} ref={li} onClick={onClick}>
      <div>{suggest.label}</div>
      <div className={styles.listItemAddress}>
        {(suggest as Suggest).subTitle}
      </div>
    </li>
  );
};

export default SuggestItem;
