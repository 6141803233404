const directionsService = window.google && new google.maps.DirectionsService();

/**
 * Returns the directions url.
 */
export function getDirectionsUrl(
  origin: google.maps.LatLngLiteral | undefined,
  destination: google.maps.LatLngLiteral
): string {
  const startPosition = (origin && `${origin.lat},${origin.lng}`) || '';

  return (
    'https://www.google.de/maps/dir' +
    `/${startPosition}` +
    `/${destination.lat},${destination.lng}` +
    '/data=!3m1!4b1!4m4!4m3!2m1!1b1!3e0'
  );
}

/**
 * Returns a promise which resolves to the calculated directions
 * and rejects with an error.
 */
export function getDistance(
  origin: google.maps.LatLngLiteral,
  destination: google.maps.LatLngLiteral
  // eslint-disable-next-line no-undef
): Promise<google.maps.Distance> {
  return new Promise((resolve, reject) => {
    directionsService.route(
      {
        origin: new google.maps.LatLng(origin),
        destination: new google.maps.LatLng(destination),
        travelMode: google.maps.TravelMode.WALKING
      },
      (response, status) => {
        if (status === 'OK') {
          resolve(response.routes[0].legs[0].distance);
        } else {
          reject(status);
        }
      }
    );
  });
}
