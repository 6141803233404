import config from '../config';

import {Poi} from '../../typescript/poi';
/**
 * Creates the anchor href for a change request mail for a given poi.
 */
export default function mailTemplate(poi: Poi) {
  const name = poi.address.name.trim();

  /* eslint-disable max-len */
  const subject = encodeURI(
    `MOKLI-Eintrag für "${name}" ist nicht korrekt - bitte ändern!`
  );
  const body = encodeURI(`Liebe MOKLI-Mitarbeiter,

bitte ändert unseren Eintrag für "${name}" wie folgt:

Bitte gib hier deinen Vorschlag ein / Please enter your suggested correction here:
  `);

  /* eslint-enable */

  return `mailto:${config.contactEmail}?subject=${subject}&body=${body}`;
}
