export enum LANGUAGES {
  de = 'de',
  en = 'en',
  ua = 'ua',
  ar = 'ar',
  pl = 'pl',
  ru = 'ru'
}

export type LanguageStore = Partial<Record<LANGUAGES, string>>;

export type Translation = {
  meta: {
    id: string;
  };
  languages: LanguageStore;
};

export type Locale = { [key: string]: string };

export type Locales = {
  [language: string]: Locale;
};
