.container {
  display: inline-block;
  color: currentColor;
  width: 3em;
  height: 3em;
}
.icon {
  width: 100%;
  height: 100%;
  fill: currentColor;
}
