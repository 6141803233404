import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://material.io/icons/#ic_keyboard_arrow_down
 */
const DownIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('down-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="down icon">
      <title>DownIcon</title>
      <path fill={fill} d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
    </svg>
  );
};

export default DownIcon;
