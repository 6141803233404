import { Poi } from '../../../typescript/poi';
import config, { mapStyles, markerPath, clusterOptions } from '../../config';
// @ts-ignore external lib
import MarkerClusterer from '../../lib/node-js-marker-clusterer';

import * as markerIcons from '../../../assets/markers/marker-icons';

type MapOptions = {
  containerElement: HTMLDivElement;
  center: google.maps.LatLngLiteral;
  onClick: () => void;
};

/**
 * Abstracting class around google maps.
 */
class MapCanvas {
  map: google.maps.Map | undefined = undefined;
  markers: {
    [key: string]: any;
  }[] = [];
  markerCluster: {
    [key: string]: any;
  } = {};
  iconType = '';

  /**
   * Calls map initialization with given MapOptions
   */
  constructor(mapOptions: MapOptions) {
    this.initMap(mapOptions);
  }

  /**
   * Triggers the map resize event.
   */
  resize(): void {
    setTimeout(() => google.maps.event.trigger(this.map, 'resize'), 100);
  }

  /**
   * Pans the map to the specified position.
   */
  panTo = (position: google.maps.LatLngLiteral): void => {
    this.map?.panTo(position);
  };

  /**
   * Sets the zoom of teh map.
   */
  setZoom = (zoom: number): void => {
    this.map?.setZoom(zoom);
  };

  /**
   * Calls fit bounds of the map instance.
   */
  fitBounds = (bounds: {
    east: number;
    north: number;
    south: number;
    west: number;
  }): void => {
    setTimeout(() => this.map?.fitBounds(bounds), 200);
  };

  /**
   * Update a marker to focused mode (highlighted/activated).
   */
  blurMarker = (marker: { [key: string]: any }): void => {
    // @ts-ignore implicitly any
    marker.setIcon(this.getMarkerIcon(markerIcons[this.iconType]));
    marker.setZIndex(1);
  };

  /**
   * Update a marker to non-focused mode (de-highlighted/deactivated).
   */
  focusMarker = (marker: { [key: string]: any }): void => {
    // @ts-ignore implicitly any
    marker.setIcon(this.getMarkerIcon(markerIcons[`${this.iconType}Active`]));
    marker.setZIndex(1000);
  };

  /**
   * Creates a single marker.
   */
  addMarker = (
    position: google.maps.LatLngLiteral,
    iconName: string,
    onClick: (marker: google.maps.Marker) => void
  ): google.maps.Marker => {
    const marker = new google.maps.Marker({
      position,
      icon: this.getMarkerIcon(iconName)
    });

    if (onClick) {
      marker.addListener('click', () => onClick(marker));
    }

    return marker;
  };

  /**
   * Creates markers from a list of Pois.
   */
  addMarkers = ({
    pois,
    iconType,
    onClick
  }: {
    pois: Array<Poi>;
    iconType: string;
    onClick: () => void;
  }): {
    [key: string]: any;
  }[] => {
    this.iconType = iconType;
    this.markers = pois.map((poi) => {
      const marker: google.maps.Marker & { poi?: Poi } = this.addMarker(
        poi.position,
        // @ts-ignore implicitly any
        markerIcons[iconType],
        onClick
      );
      marker.poi = poi;

      return marker;
    });

    const options = clusterOptions;

    if (this.markerCluster instanceof MarkerClusterer) {
      this.markerCluster.clearMarkers();
    }

    // @ts-ignore
    options.styles = config.clusterStyles[iconType];

    this.markerCluster = new MarkerClusterer(this.map, this.markers, options);

    return this.markers;
  };

  /**
   * Returns the marker icon Object.
   * @param {string} filename The filename of the icon.
   * @return {Object}
   */
  getMarkerIcon = (filename: string): google.maps.Icon => ({
    url: filename,
    scaledSize: new google.maps.Size(60, 60),
    size: new google.maps.Size(60, 60),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(30, 30)
  });

  /**
   * Initialize the Map
   * @param {Element} element The map's DOM-element
   */
  initMap = ({ containerElement, center, onClick }: MapOptions): void => {
    this.map = new google.maps.Map(containerElement, {
      // @ts-ignore
      styles: mapStyles,
      center,
      zoom: 12,
      disableDefaultUI: true,
      zoomControl: true
    });

    this.map.addListener('click', onClick);
  };
}

export default MapCanvas;
