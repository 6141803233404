/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: string;
  width?: string;
  onClick?: () => void;
  className?: string;
};

/**
 * The Share icon.
 */
const ShareIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className = ''} = props;

  const iconClassNames = cx('share-icon icon', className);

  return (
    <svg
      onClick={event => {
        event.preventDefault();
        event.stopPropagation();

        onClick && onClick();
      }}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="share icon">
      <title>ShareIcon</title>
      <path
        fill={fill}
        d="M18 16.08c-.76 0-1.44.3-1.96.77L8.91 12.7c.05-.23.09-.46.09-.7s-.04-.47-.09-.7l7.05-4.11c.54.5 1.25.81 2.04.81 1.66 0 3-1.34 3-3s-1.34-3-3-3-3 1.34-3 3c0 .24.04.47.09.7L8.04 9.81C7.5 9.31 6.79 9 6 9c-1.66 0-3 1.34-3 3s1.34 3 3 3c.79 0 1.5-.31 2.04-.81l7.12 4.16c-.05.21-.08.43-.08.65 0 1.61 1.31 2.92 2.92 2.92 1.61 0 2.92-1.31 2.92-2.92s-1.31-2.92-2.92-2.92z"
      />
    </svg>
  );
};

export default ShareIcon;
