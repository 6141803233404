// eslint-disable-next-line @typescript-eslint/ban-ts-comment

import React, { useRef, useEffect, useState, useCallback } from 'react';
import { useMatch, useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import isEqual from 'lodash.isequal';

import { getActivePoi, filterPois, getPoiDistance } from '../../store/pois';
import { getParentCategory } from '../../store/filter';
import {
  requestLocation,
  isAppOffline,
  getCookiePreferences,
  isMapsLoaded
} from '../../store/app';
import { getDictionary } from '../../store/locales';

import { hasLocationApi } from '../../api/location';

import RequestLocationButton from '../requestLocationButton/requestLocationButton';

import config from '../../config';

import * as styles from './styles.module.css';
import { StoreState } from '../../../typescript/store';
import MapCanvas from './map-canvas';

/**
 * The Map-Component
 */
const Map = () => {
  const pois = useSelector(filterPois, isEqual);
  const activePoi = useSelector(getActivePoi);
  const parentCategory = useSelector(getParentCategory);
  const isOffline = useSelector(isAppOffline);
  const mapsLoaded = useSelector(isMapsLoaded);
  const cookiePreferences = useSelector(getCookiePreferences, isEqual);
  const locales = useSelector(getDictionary);
  const isCompareMode = useSelector(
    (state: StoreState) => state.app.isCompareMode
  );
  const center = useSelector(
    (state: StoreState) => state.app.mapCenter,
    isEqual
  );
  const userPosition = useSelector(
    (state: StoreState) => state.app.position,
    isEqual
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const match = useMatch('/category/:category/*');

  const category = match?.params?.category;

  const markers = useRef<
    {
      [key: string]: any;
    }[]
  >([]);

  const [map, setMap] = useState<{
    [key: string]: any;
  } | null>(null);

  const [mapContainer, setMapContainer] = useState<HTMLDivElement | null>(null);
  const mapRef = useCallback((node: HTMLDivElement) => {
    node && setMapContainer(node);
  }, []);

  /**
   * Initialize the Map
   */
  useEffect(() => {
    if (mapContainer) {
      const mapInstance = new MapCanvas({
        containerElement: mapContainer,
        center,
        onClick: onMapClick
      });

      setMap(mapInstance);

      mapInstance.resize();

      map?.panTo(center);
      mapInstance.fitBounds(config.defaultMapCenter.bounds);
    }
  }, [mapContainer]);

  /**
   * Create markers from new POIs
   */
  useEffect(() => {
    if (parentCategory) {
      setMarkers();
    }
  }, [pois, parentCategory, map]);

  /**
   * Switch offline mode
   */
  useEffect(() => {
    if (!isOffline) {
      map?.resize();
    }
  }, [isOffline, map]);

  /**
   * Update map on category change
   */
  useEffect(() => {
    if (category && !activePoi) {
      map?.panTo(config.defaultMapCenter);
      map?.fitBounds(config.defaultMapCenter.bounds);
      map?.resize();
    }
  }, [category, map]);

  /**
   * Change marker of active POI, zoom and pan
   */
  useEffect(() => {
    if (activePoi && map && markers.current) {
      markers.current
        .filter((marker) => marker.poi.id !== activePoi.id)
        .forEach(map.blurMarker);

      markers.current
        .filter((marker) => marker.poi.id === activePoi.id)
        .forEach((poi) => {
          map.focusMarker(poi);
        });

      map?.panTo(activePoi.position);
      map?.setZoom(14);

      dispatch(getPoiDistance(activePoi));
    }
  }, [activePoi, pois, map]);

  /**
   * Update the map center
   */
  useEffect(() => {
    map?.panTo(center);
  }, [center]);

  /**
   * Update map center on user position change
   */
  useEffect(() => {
    if (activePoi && userPosition) {
      dispatch(getPoiDistance(activePoi));
      map?.panTo(userPosition);
    }
  }, [userPosition]);

  /**
   * Sets the markers from given poi list.
   * Removes old markers first if there are any.
   */
  const setMarkers = () => {
    if (markers.current) {
      markers.current.forEach((marker) => marker.setMap(null));
      markers.current.length = 0;
    }

    markers.current = map?.addMarkers({
      pois,
      iconType: parentCategory,
      onClick: onMarkerClick
    });
  };

  /**
   * Handles the marker click event. Activating the clicked marker.
   */
  const onMarkerClick = (marker: { [_: string]: any }) => {
    const isActiveMarker = activePoi && activePoi.id === marker.poi.id;

    if (!isActiveMarker) {
      navigate(`/category/${category}/poi/${marker.poi.meta.slug}`);
    }
  };

  /**
   * Handles clicks on the map. Removing the current active poi.
   */
  const onMapClick = () => {
    if (activePoi) {
      navigate(`/category/${category}`);
    }
  };

  /**
   * Calls the request location action.
   */
  const onLocationRequest = () => {
    dispatch(requestLocation());
  };

  const containerClasses = cx(styles.container, !category && styles.hidden);
  const messageContainerClasses = cx(
    styles.messageContainer,
    'overlay',

    (!isOffline || isCompareMode) &&
      cookiePreferences?.mapsAccepted &&
      styles.hidden
  );
  const mapCanvasClasses = cx(
    styles.containerInner,
    (isOffline || isCompareMode || !cookiePreferences?.mapsAccepted) &&
      styles.hidden
  );

  return (
    <div className={containerClasses}>
      <div className={messageContainerClasses}>
        <span className={styles.notUsableMessage}>
          {cookiePreferences?.mapsAccepted
            ? locales.offlineMapHint
            : locales.mapUsageDeclinedHint}
        </span>
      </div>
      {mapsLoaded && (
        <div className={mapCanvasClasses}>
          <div className={styles.mapCanvas} ref={mapRef} />
          {hasLocationApi() && (
            <RequestLocationButton requestLocation={onLocationRequest} />
          )}
        </div>
      )}
    </div>
  );
};

export default Map;
