import { Routes, Route } from 'react-router';
import { HashRouter } from 'react-router-dom';
import React from 'react';
import ReactDOM from 'react-dom';
import { SWRConfig } from 'swr';

import { createStore, combineReducers, applyMiddleware } from 'redux';
import { Provider, useSelector } from 'react-redux';
import thunkMiddleware from 'redux-thunk';
import { createLogger } from 'redux-logger';
// @ts-ignore no type declaration file for module 'redux-dynamic-middlewares
import dynamicMiddlewares, { addMiddleware } from 'redux-dynamic-middlewares';

require('isomorphic-fetch');

import './main.css';

import Meta from './components/meta/meta';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import Wrapper from './components/wrapper';
import CategorySelectionFilter from './components/category-selection-filter/category-selection-filter';
import Page from './components/page/page';
import CategorySelection from './components/category-selection/category-selection';
import Map from './components/map/map';
import Infowindow from './components/infowindow/infowindow';
import Loading from './components/loading-overlay/loading-overlay';
import Compare from './components/compare/compare';
import CookieDisclaimer from './components/cookie-disclaimer/cookie-disclaimer';

import pois, { fetchPois } from './store/pois';
import app, {
  getCookiePreferences,
  setMapsLoaded,
  toggleConnectionState
} from './store/app';
import filter from './store/filter';
import locales, { fetchTranslations } from './store/locales';
import { Store, StoreState } from '../typescript/store';

import { onConnectionChange } from './lib/connection-check';
import { loadAnalytics, loadGoogleMaps } from './lib/load-scripts';
import { swrConfig } from './lib/swr-config';
import config from './config';

const middlewares = [thunkMiddleware];

if (!['production', 'staging'].includes(config.nodeEnv)) {
  const loggerMiddleware = createLogger({ collapsed: true });
  // @ts-ignore
  middlewares.push(loggerMiddleware);
}

const store: Store = createStore(
  combineReducers({
    pois,
    app,
    filter,
    locales
  }),
  // @ts-ignore
  applyMiddleware(...middlewares, dynamicMiddlewares)
);

onConnectionChange((connectionState) =>
  store.dispatch(toggleConnectionState(connectionState))
);

store.dispatch(fetchPois());
store.dispatch(fetchTranslations());

const rootElement = document.querySelector('.root');

const PoisLoading = () => {
  const poisFetched = useSelector((state: StoreState) => state.app.poisFetched);
  if (poisFetched) {
    return null;
  }
  return <Loading />;
};

const CookiePreferences = () => {
  const cookiePrefs = useSelector(getCookiePreferences);
  if (cookiePrefs) {
    if (cookiePrefs.analyticsAccepted) {
      loadAnalytics();
    }
    if (cookiePrefs.mapsAccepted) {
      loadGoogleMaps(() => {
        store.dispatch(setMapsLoaded());
      });
    }
    return null;
  }
  return <CookieDisclaimer />;
};

ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <SWRConfig value={swrConfig}>
        <Wrapper>
          <CookiePreferences />
          <Meta />
          <Header />
          <Routes>
            <Route path="/" element={<CategorySelection />} />
            <Route
              path="/category/:category"
              element={<CategorySelectionFilter />}
            />
            <Route
              path="/compare/:category?"
              element={<CategorySelectionFilter />}
            />
            <Route path="/:page" element={<Page />} />
          </Routes>
          <Footer />
          <Map />
          <Compare />
          <Infowindow />
          <PoisLoading />
        </Wrapper>
      </SWRConfig>
    </HashRouter>
    {!['production'].includes(config.nodeEnv) && (
      <div className="debug">{config.nodeEnv}</div>
    )}
  </Provider>,
  rootElement
);
