import config from '../config';

export const loadGoogleMaps = (callback: () => void): void => {
  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    const script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleMapsAPIKey}&libraries=places`;
    script.id = 'googleMaps';
    document.body.appendChild(script);
    script.onload = () => {
      if (callback) {
        callback();
      }
    };
  }
  if (existingScript && callback) {
    callback();
  }
};

export const loadAnalytics = (): void => {
  const existingScript = document.getElementById('matomoAnalytics');
  if (!existingScript) {
    // @ts-ignore - matomo analytics is not typed
    var _paq = (window._paq = window._paq || []);
    /* tracker methods like "setCustomDimension" should be called before "trackPageView" */ _paq.push(
      ['trackPageView']
    );
    _paq.push(['enableLinkTracking']);
    (function () {
      var u = '//matomo.kasperdev.de/';
      _paq.push(['setTrackerUrl', u + 'matomo.php']);
      _paq.push(['setSiteId', '99']);
      var d = document,
        g = d.createElement('script'),
        s = d.getElementsByTagName('script')[0];
      g.async = true;
      g.src = u + 'matomo.js';
      g.id = 'matoAnalytics';
      s.parentNode?.insertBefore(g, s);
    })();
  }
};
