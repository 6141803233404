import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import logo from 'url:../../../assets/logo.svg';
import * as categoryIcons from '../../../assets/category-icons/category-images';

import * as styles from './styles.module.css';

import { getParentCategory } from '../../store/filter';
import { getDictionary } from '../../store/locales';

import BackArrowIcon from '../icons/back-arrow-icon';
import MenuIcon from '../icons/menu-icon';
import CloseIcon from '../icons/close-icon';
import Menu from '../menu/menu';
import { useMatch, useNavigate } from 'react-router';

/**
 * The header component. Shows information about the app and handles
 * menu show and hide and the back button.
 */
const Header = (): React.ReactElement => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const navigate = useNavigate();
  const pageMatch = useMatch('/:page');
  const categoryMatch = useMatch('/category/:subcategory/*');
  const category = categoryMatch?.params?.subcategory;
  /**
   * The menu toggle.
   */
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const locales = useSelector(getDictionary);
  const parentCategory = useSelector(getParentCategory);

  const containerClass = cx(
    styles.header,
    isMenuOpen && styles.headerFullScreen
  );
  const headerMenuClasses = cx(
    !isMenuOpen && styles.overlay,
    styles.headerMenu,
    isMenuOpen && styles.headerMenuFullScreen
  );
  const logoClass = cx(
    styles.logo,
    (!parentCategory || isMenuOpen) && styles.logoBig
  );
  const headerLogo =
    // @ts-ignore implict any
    !parentCategory || isMenuOpen ? logo : categoryIcons[parentCategory];

  const showBackButton = (pageMatch || categoryMatch) && !isMenuOpen;

  return (
    <header className={containerClass}>
      <div className={headerMenuClasses}>
        {showBackButton && (
          <BackArrowIcon
            className={styles.backArrow}
            width={30}
            height={30}
            onClick={() => {
              navigate('/');
            }}
          />
        )}

        <img alt="logo" className={logoClass} src={headerLogo} />

        {!isMenuOpen && (
          <MenuIcon
            className={styles.menuToggle}
            width={30}
            height={30}
            onClick={() => toggleMenu()}
          />
        )}

        {isMenuOpen && (
          <CloseIcon
            className={styles.menuToggle}
            width={30}
            height={30}
            onClick={() => toggleMenu()}
          />
        )}

        {!category && !isMenuOpen && (
          <div className={styles.introText}>{locales.introtext}</div>
        )}
      </div>
      {isMenuOpen && <Menu onMenuItemSelect={toggleMenu} />}
    </header>
  );
};

export default Header;
