/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height: number;
  width: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_time
 */
const TimeIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('time-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="time icon">
      <title>TimeIcon</title>
      <path
        fill={fill}
        d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zM12 20c-4.42 0-8-3.58-8-8s3.58-8 8-8 8 3.58 8 8-3.58 8-8 8z"
      />
      <path fill={fill} d="M12.5 7H11v6l5.25 3.15.75-1.23-4.5-2.67z" />
    </svg>
  );
};

export default TimeIcon;
