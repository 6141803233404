import React from 'react';
import cx from 'classnames';

import EditIcon from '../icons/edit-icon';
import DirectionsIcon from '../icons/directions-icon';
import PrintIcon from '../icons/print-icon';
import CheckIcon from '../icons/check-icon';

import * as styles from './styles.module.css';

const icons: { [_: string]: any } = {
  print: PrintIcon,
  edit: EditIcon,
  directions: DirectionsIcon,
  check: CheckIcon
};

type Props = {
  text: string;
  iconName: string;
  to?: string;
  target?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * The HTML Link component that looks like a button
 */
const Link = ({
  text,
  iconName,
  to,
  target = '_blank',
  className = '',
  onClick = () => null,
  disabled
}: Props): React.ReactElement => {
  const Icon = icons[iconName];
  const classes = cx(styles.container, {
    [className]: className
  });

  return (
    <a
      onClick={onClick}
      // @ts-ignore
      disabled={disabled}
      target={target}
      rel="noopener noreferrer"
      href={to}
      className={classes}
    >
      <span className={styles.text}>{text}</span>
      <Icon className={styles.icon} width={22} height={22} />
    </a>
  );
};

export default Link;
