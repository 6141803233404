import React, { useState } from 'react';
import cx from 'classnames';

import LocationList from '../../components/location-list/location-list';
import Filter from '../../components/filter/filter';
import CloseIcon from '../icons/close-icon';
import SettingsIcon from '../icons/settings-icon';

import { getDictionary } from '../../store/locales';
import { toggleCompareMode as toggleCompareModeAction } from '../../store/app';
import { removeComparePois } from '../../store/pois';

import * as styles from './styles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { StoreState } from '../../../typescript/store';

/**
 * The filter component.
 */
const App = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const locales = useSelector(getDictionary);
  const isCompareMode = useSelector(
    (state: StoreState) => state.app.isCompareMode
  );

  /**
   * Toggles the filter collapsed state.
   */
  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  /**
   * Toggles the filter collapsed state.
   */
  const toggleCompareMode = () => {
    if (isCompareMode) {
      dispatch(removeComparePois());
    }
    dispatch(toggleCompareModeAction());
  };

  return (
    <div className={styles.container}>
      <LocationList>
        {isFilterVisible ? (
          <CloseIcon
            onClick={toggleFilter}
            className={styles.icon}
            width={30}
            height={30}
          />
        ) : (
          <SettingsIcon
            onClick={toggleFilter}
            className={styles.icon}
            width={30}
            height={30}
          />
        )}

        {isFilterVisible && <Filter />}
        <div className={styles.compare}>
          <button
            onClick={toggleCompareMode}
            className={cx(
              styles.compareButton,
              !isCompareMode && styles.active
            )}
          >
            <span>{locales.standardView}</span>
          </button>
          <button
            onClick={toggleCompareMode}
            className={cx(styles.compareButton, isCompareMode && styles.active)}
          >
            <span>{locales.compareView}</span>
          </button>
        </div>
      </LocationList>
    </div>
  );
};

export default App;
