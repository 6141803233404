import config from '../config';

export type CookiePreferences = {
  mapsAccepted: boolean;
  analyticsAccepted: boolean;
};

const getCookiePreferences = (): CookiePreferences | null => {
  const cookies = document.cookie.split(';');
  const karunaCookie = cookies.find(
    cookie => cookie.trim().indexOf(config.cookieName) === 0
  );
  if (!karunaCookie) {
    return null;
  }
  const cookieConfig = karunaCookie.split('=').slice(-1)[0];
  return JSON.parse(cookieConfig);
};

const setCookiePreferences = (cookiePrefs: CookiePreferences): void => {
  document.cookie = `${config.cookieName}=${JSON.stringify(
    cookiePrefs
  )}; max-age=${config.cookieMaxAge}`;
};

export default {getCookiePreferences, setCookiePreferences};
