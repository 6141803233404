import React, { useReducer } from 'react';
import cx from 'classnames';

import * as styles from './styles.module.css';

import { updateFilter } from '../../store/filter';

import PersonalFilter from './personal-filter';
import ConvenienceFilter from './convenience-filter';
import { useDispatch } from 'react-redux';

/**
 * The filter component. Here the use can set and remove filter
 * settings which should control the selection on the map.
 */
const Filter = (): React.ReactElement => {
  const dispatch = useDispatch();
  const [convenienceFilter, dispatchConvenienceFilter] = useReducer(
    (
      state: { [_: string]: boolean },
      action: { checked: boolean; name: string }
    ) => {
      if (!action.checked) {
        delete state[action.name];
      } else {
        state[action.name] = true;
      }

      return state;
    },
    {}
  );
  const [personalFilter, dispatchPersonalFilter] = useReducer(
    (
      state: { [_: string]: string },
      action: { value: string; name: string }
    ) => {
      if (action.value === 'none') {
        delete state[action.name];
      } else {
        state[action.name] = action.value;
      }
      return state;
    },
    {}
  );

  /**
   * Triggers the onFilterChange props handler.
   */
  const onFilterChange = () => {
    dispatch(updateFilter({ convenienceFilter, personalFilter }));
  };

  /**
   * Handles change on the convenience filter.
   */
  const onPersonalFilterChange = ({
    target
  }: React.ChangeEvent<HTMLInputElement>) => {
    // @ts-ignore
    const { name, value } = target;
    dispatchPersonalFilter({ name, value });
    onFilterChange();
  };

  /**
   * Handles change on the convenience filter.
   */
  const onConvenienceFilterChange = ({
    target
  }: React.MouseEvent<HTMLAnchorElement>) => {
    // @ts-ignore
    const { name, checked } = target;

    dispatchConvenienceFilter({ name, checked });

    onFilterChange();
  };

  /**
   * Returns the component.
   */
  const containerClass = cx(styles.container, 'overlay');
  const filterSectionsClassnames = cx(styles.filterSections, 'overlay');

  return (
    <div className={containerClass}>
      <div className={filterSectionsClassnames}>
        <PersonalFilter
          onFilterChange={onPersonalFilterChange}
          {...personalFilter}
        />

        <ConvenienceFilter
          onFilterChange={onConvenienceFilterChange}
          {...convenienceFilter}
        />
      </div>
    </div>
  );
};

export default Filter;
