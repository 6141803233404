/* eslint-disable no-undef */
/**
 * Returns a promise which resolves with the reverse geocoded current position.
 */
export function geolocate(): Promise<GeolocationPosition> {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(resolve, reject);
  });
}

/**
 * Returns boolean if the browser supports the geolocation feature.
 */
export function hasLocationApi(): boolean {
  return 'geolocation' in navigator;
}
