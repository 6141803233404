.categoriesContainer {
  padding: 0.9375em;
  width: 100%;
  margin: 0 auto;
  color: var(--main-color);
}

@media screen and (min-width: 580px) {
  .categoriesContainer {
    max-width: 40rem;
    width: 40rem;
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .categoriesContainer {
    width: 100%;
  }
}
.categoriesHeader {
  position: relative;
  margin-bottom: 0.625em;
}
.removeCategory {
  position: absolute;
  right: 0;
  top: -0.1875em;
}
html[dir='rtl'] .removeCategory {
  right: auto;
  left: 0;
}
.categoriesContent {
  display: flex;
  flex-wrap: wrap;
}
.subCategories {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subCategories .categoriesContent {
  width: 100%;
}

.subCategories .category {
  flex: 1 1 50%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 0;
}
@media (min-width: 410px) {
  .subCategories .category {
    flex: 1 1 50%;
  }
}
@media (min-width: 580px) {
  .subCategories .category {
    flex: 1 1 50%;
  }
}
.subCategories .categoryIcon {
  width: 4em;
  margin-bottom: 1.25em;
}

.subCategories .parentCategory .categoryIcon {
  width: 5rem;
  margin-bottom: 1.25em;
}

@media (min-width: 580px) {
  .subCategories .parentCategory .categoryIcon {
    width: 7rem;
  }
}
@media (min-width: 875px) {
  .subCategories .categoryIcon {
    width: 5.3125em;
  }
}
.category {
  display: flex;
  align-items: center;
  flex-basis: 50%;
  flex-grow: 0;
  flex-direction: column;
  margin-bottom: 1.25em;
  text-align: center;
  box-sizing: border-box;
  overflow: hidden;
}

.categoryIcon {
  width: 4.0625em;
  margin-bottom: 0.625em;
}

@media (min-width: 580px) {
  .categoryIcon {
    width: 5.3125em;
  }
}
.parentCategory {
  height: 100%;
  flex: 0 1 33%;
  padding-right: 0.75em;
}
.hidden {
  display: none;
}
