export const swrConfig = {
  refreshInterval: 30000,
  fetcher: async (url: string) => {
    const res = await fetch(url);

    // If the status code is not in the range 200-299,
    // we still try to parse and throw it.
    if (!res.ok) {
      const error: Error & {
        info?: {[_: string]: any};
        status?: number;
      } = new Error('An error occurred while fetching the data.');

      error.info = res.json();
      error.status = res.status;

      throw error;
    }

    return res.json();
  },
  onErrorRetry: (error: Error & {status?: number}) => {
    // Never retry on 404.
    if (error.status === 404) {
      return null;
    }
  }
};
