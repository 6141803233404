import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height: number;
  width: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_directions
 */
const DirectionsIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('directions-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="directions icon">
      <title>DirectionsIcon</title>
      <path
        fill={fill}
        d="M21.71 11.29l-9-9c-.39-.39-1.02-.39-1.41 0l-9 9c-.39.39-.39 1.02 0 1.41l9 9c.39.39 1.02.39 1.41 0l9-9c.39-.38.39-1.01 0-1.41zM14 14.5V12h-4v3H8v-4c0-.55.45-1 1-1h5V7.5l3.5 3.5-3.5 3.5z"
      />
    </svg>
  );
};

export default DirectionsIcon;
