import React from 'react';
import cx from 'classnames';

import FacebookIcon from '../icons/facebook-icon';
import TwitterIcon from '../icons/twitter-icon';
import MailIcon from '../icons/mail-icon';
import WhatsappIcon from '../icons/whatsapp-icon';
import InstagramIcon from '../icons/instagram';

import * as styles from './styles.module.css';

const icons: { [_: string]: any } = {
  facebook: FacebookIcon,
  twitter: TwitterIcon,
  instagram: InstagramIcon,
  mail: MailIcon,
  whatsapp: WhatsappIcon
};

const links: {
  [key: string]: (arg0: { [key: string]: string }) => string;
} = {
  facebook: ({ url }) =>
    `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
  instagram: ({ url }) => `https://www.instagram.com/refugeo_com/`,
  whatsapp: ({ text }) =>
    `https://api.whatsapp.com/send?text=${encodeURIComponent(text)}`,
  twitter: ({ text, url }) =>
    `https://twitter.com/intent/tweet?text=${encodeURIComponent(
      text
    )}&url=${encodeURIComponent(url)}`,
  mail: ({ recipient, subject, body }) =>
    `mailto:${recipient}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`
};

type Props = {
  network: string;
  // @ts-ignore I don't understand
  className?: string;
  [key: string]: string;
};

/**
 * Social network share link
 */
function ShareLink({ network, className, ...rest }: Props): React.ReactElement {
  const Icon = icons[network];
  const target = network === 'mail' ? '_self' : '_blank';
  const classes = cx(styles.container, className && className);

  return (
    <a className={classes} target={target} href={links[network](rest)}>
      <Icon className={styles.icon} />
    </a>
  );
}

export default ShareLink;
