.container {
  position: relative;
  width: 100%;
  padding: 0.6875em 0.625em 0.6875em 0.9375em;
  background-color: #fff;
  box-shadow: var(--box-shadow);
  z-index: 1;
  box-sizing: border-box;
}
@media (min-width: 875px) {
  .container {
    position: absolute;
    top: 4.375em;
    left: 0.9375em;
    width: 21.875em;
    padding: 0;
    min-height: 0;
    box-shadow: var(--box-shadow);
    background-color: #fff;
  }
}
.container:before {
  opacity: 0.3;
}
.container:before {
  opacity: 0.3;
}
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filterSections {
  position: absolute;
  left: 0;
  width: 100%;
  padding: 0 0.9375em 0.6875em;
  background-color: #fff;
  box-sizing: border-box;
  z-index: 1;
  box-shadow: var(--box-shadow);
}
.filterSections:before {
  opacity: 0.3;
}
@media (min-width: 875px) {
  .filterSections {
    padding: 0.9375em;
  }
}
.filterSection {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-grow: 1;
  border: none;
  margin: 0;
  padding: 0.5em 0;
}
.sectionTitle {
  display: inline-block;
  flex: 0 0 6.25em;
}
.sectionContent {
  display: flex;
  flex: 1 0;
}
.personalFilterContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.categoriesContainer {
  flex-grow: 1;
  padding: 0.9375em;
  background: #e8f8ee;
}
.categoriesHeader {
  position: relative;
  margin-bottom: 0.625em;
}
.resetCategory {
  position: absolute;
  right: 0;
  top: -3px;
}
.categoriesContent {
  display: flex;
  flex-wrap: wrap;
}
.category,
.subCategory {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 0 0 calc(33% - 0.875em);
  flex-basis: calc(33% - 0.875em);
  margin: 0.4375em;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 1.25em;
}
.category .categoryIcon,
.subCategory .categoryIcon {
  width: 3.75em;
  max-width: 100%;
  margin-bottom: 0.625em;
}
.subCategory {
  flex: 0 0 calc(25% - 0.875em);
  flex-basis: calc(25% - 0.875em);
  margin: 0.4375em;
}
.hidden {
  display: none;
}
.label,
.labelPersonalFilter {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.1875em;
  height: 2.1875em;
  margin-right: 0.9375em;
}
.labelPersonalFilter {
  border-radius: 50%;
}
