.overlay:before {
  position: absolute;
  content: '';
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: currentColor;
  opacity: 0.7;
}

.overlay * {
  position: relative;
}

.header,
.headerFullScreen {
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
  z-index: 2;
}
.headerFullScreen {
  position: fixed;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.headerMenu,
.headerMenuFullScreen {
  position: relative;
}
.headerMenuFullScreen {
  background: var(--primaryBackgroundColor);
  position: fixed;
  z-index: 1;
  width: 100%;
}
.backArrow {
  position: absolute;
  top: 0.75rem;
  left: 0.625rem;
  width: var(--icon-width);
  height: var(--icon-height);
  fill: var(--main-color);
}

.logo,
.logoBig {
  display: block;
  width: 2.8125rem;
  padding: 0.375rem 0 0.375rem;
  margin: auto;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logo,
  .logoBig {
    height: 2.8rem;
  }
}
.logoBig {
  width: 6.25rem;
  padding: 1rem 0 0.625rem;
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .logoBig {
    height: 4rem;
  }
}
@media (min-width: 580px) {
  .logoBig {
    width: 8.31rem;
  }
}
.menuToggle {
  position: absolute;
  top: 0.75rem;
  right: 0.625rem;
  width: var(--icon-width);
  height: var(--icon-height);
  fill: var(--main-color);
}

.introText {
  margin: 0 auto;
  padding: 0.75rem 2rem 2rem;
  text-align: center;
  color: var(--secondary-color);
}

@media (min-width: 875px) {
  .introText {
    font-size: 1.2rem;
  }
}
