import React from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';

import ShareLink from '../share-link/share-link';

import { getDictionary } from '../../store/locales';
import { isAppOffline } from '../../store/app';

import * as styles from './styles.module.css';
import config from '../../config';
import { useMatch } from 'react-router';

/**
 * Returns the footer component.
 */
function Footer(): React.ReactElement {
  const locales = useSelector(getDictionary);
  const isOffline = useSelector(isAppOffline);
  const match = useMatch('/category/:category/*');
  const classes = cx(
    styles.container,
    isOffline && styles.containerOffline,
    match?.params?.category && styles.hidden
  );

  return (
    <div className={classes}>
      <ShareLink
        className={styles.shareIcon}
        network="facebook"
        url={config.webRoot}
      />
      <ShareLink
        className={styles.shareIcon}
        network="whatsapp"
        text={locales.pageShareWhatsapp}
      />
      <ShareLink
        className={styles.shareIcon}
        network="instagram"
        url={config.webRoot}
        text={locales.pageShareInstagram}
      />
      <ShareLink
        className={styles.shareIcon}
        network="mail"
        recipient={config.contactEmail}
        subject={locales.pageShareMailSubject}
        body={locales.pageShareMailCopy}
      />
    </div>
  );
}

export default Footer;
