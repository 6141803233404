import { LocaleDocument } from '../../../typescript/firestore';
import { LanguageStore, Translation } from '../../../typescript/locale';
import { availableLanguages } from '../../constants/language';

/**
 * Returns parsed locales api response.
 */
export default function parseLocalesResponse(
  response: Array<LocaleDocument>
): Translation[] {
  return response.map((document) => {
    return {
      meta: {
        id: document.key
      },
      languages: availableLanguages.reduce<LanguageStore>((store, language) => {
        store[language] = document[language];
        return store;
      }, {})
    };
  });
}
