import { useSelector } from 'react-redux';
import React from 'react';

import CategoryIcon from './category-icon';

import { getDictionary } from '../../store/locales';

import * as styles from './styles.module.css';
import { ParentCategoryId } from '../../../typescript/poi';
import { categories } from '../../constants/categories';

type Props = {
  parentCategoryId: ParentCategoryId;
  onSubcategorySelect: (event: React.MouseEvent<HTMLAnchorElement>) => void;
};

/**
 * Returns the sub-category part of the component.
 */
const SubCategories = ({
  parentCategoryId,
  onSubcategorySelect
}: Props): React.ReactElement => {
  const locales = useSelector(getDictionary);
  const parentCategory = categories.find(
    (category) => category.id === parentCategoryId
  );

  return (
    <div className={styles.subCategories}>
      <div className={styles.parentCategory}>
        <CategoryIcon
          category={parentCategory}
          title={locales.showAll}
          onClick={onSubcategorySelect}
        />
      </div>
      <div className={styles.categoriesContent}>
        {categories
          .filter((category) => category.parent === parentCategoryId)
          .map((category) => (
            <CategoryIcon
              key={locales[category.id]}
              title={locales[category.id]}
              category={category}
              onClick={onSubcategorySelect}
            />
          ))}
      </div>
    </div>
  );
};
export default SubCategories;
