import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * The dogs icon
 */
const DogsIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('dogs-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="dogs icon">
      <title>DogsIcon</title>
      <path
        fill={fill}
        d="M28,29.38a1.37,1.37,0,0,1-1.36,1.45c-2.91,0-4.58-2.16-6.64-2.16s-4,2.17-6.7,2.16A1.44,1.44,0,0,1,12,29.38c0-2.75,1.75-9.56,8-9.56C25.5,19.81,28,26.62,28,29.38Zm-.3-10.71c-.71,1.24-.59,2.65.28,3.15s2.15-.09,2.87-1.33,1-3.35.11-3.85S28.41,17.43,27.69,18.66ZM9.17,20.47c.71,1.23,2,1.83,2.86,1.33s1-1.91.28-3.14-2.4-2.52-3.27-2S8.46,19.24,9.17,20.47Zm4.47-6c.24,2.08,1.62,3.62,3.08,3.46s2.45-2,2.21-4.07S17,9,15.57,9.19,13.39,12.43,13.64,14.51Zm7.45-.6c-.24,2.08.75,3.9,2.21,4.07s2.84-1.38,3.08-3.46-.73-5.17-2.19-5.34S21.33,11.83,21.08,13.91Z"
      />
    </svg>
  );
};

export default DogsIcon;
