/* eslint-disable max-len */
import React from 'react';
import cx from 'classnames';

type Props = {
  fill: string;
  height: number;
  width: number;
  onClick: () => void;
  className: string;
};

/**
 * The wifi icon
 */
const WifiIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('wifi-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="wifi icon">
      <title>WifiIcon</title>
      <path
        fill={fill}
        d="M22.58,27.59A2.58,2.58,0,1,1,20,25,2.58,2.58,0,0,1,22.58,27.59Zm-9.66-7.2,2.88,2.88a6,6,0,0,1,8.42,0l2.88-2.88a10.08,10.08,0,0,0-14.18,0Zm-2.54-2.54a13.67,13.67,0,0,1,19.25,0L32.5,15a17.73,17.73,0,0,0-25,0Z"
      />
    </svg>
  );
};
export default WifiIcon;
