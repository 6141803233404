const UPDATE_FILTER = 'filter/UPDATE_FILTER';
const SELECT_PARENT_CATEGORY = 'filter/SELECT_PARENT_CATEGORY';
const SELECT_CATEGORY = 'filter/SELECT_CATEGORY';
export const REMOVE_CATEGORY = 'filter/REMOVE_CATEGORY';

import { FilterState, Action, StoreState } from '../../typescript/store';
import { categories } from '../constants/categories';
type State = FilterState;

const initalState = {};

/**
 * Returns the store state after the reducer.
 */
export default function reducer(
  state: State = initalState,
  action: Action
): State {
  switch (action.type) {
    case UPDATE_FILTER:
      return { ...state, ...action.filter };

    case SELECT_PARENT_CATEGORY:
      return { ...state, ...{ parentCategory: action.category } };

    case SELECT_CATEGORY:
      return {
        ...state,
        ...{
          category: action.category
        }
      };

    case REMOVE_CATEGORY:
      delete state.parentCategory;
      delete state.category;
      return { ...state };

    default:
      return state;
  }
}

/**
 * Returns the update filter action.
 * @param  {Object} filter The filter.
 * @return {Object}
 */
export function updateFilter(filter: FilterState): Action {
  return {
    type: UPDATE_FILTER,
    filter
  };
}

/**
 * Returns the select category action.
 * @param  {string} category The category.
 * @return {Object}
 */
export function selectCategory(category: string): Action {
  return {
    type: SELECT_CATEGORY,
    category
  };
}

/**
 * Returns the remove category action.
 */
export function removeCategory(): Action {
  return {
    type: REMOVE_CATEGORY
  };
}

/**
 * Returns the available filters for a given category.
 */
export function getCategoryFilters(state: StoreState): string[] | null {
  const { category } = state.filter;

  if (!category) {
    return null;
  }

  return categories.find(({ id }) => id === category)?.filter || null;
}

/**
 * Selects the parent-category for a given filter with a category.
 */
export function getCategory(state: StoreState): string | null {
  const { category } = state.filter;

  if (!category) {
    return null;
  }

  return category;
}

/**
 * Selects the parent-category for a given filter with a category.
 */
export function getParentCategory(state: StoreState): string | null {
  const { category } = state.filter;

  if (!category) {
    return null;
  }

  return categories.find(({ id }) => id === category)?.parent || category;
}
