.container {
  background: #fff;
}
@media (min-width: 875px) {
  .container {
    position: absolute;
    top: 3.4375em;
    bottom: 0;
    width: 23.75em;
    z-index: 1;
  }
}
.icon {
  position: absolute;
  top: 0.8125em;
  right: 0.625em;
  cursor: pointer;
  fill: var(--main-color);
  z-index: 1;
}
html[dir='rtl'] .icon {
  margin-left: 0;
  margin-right: 0.625em;
}
.compare {
  font-size: 0.875rem;
  display: none;
  justify-content: space-between;
  min-height: 1.571428571428571em;
  padding: 1.071428571428571em;
  min-height: 3.714285714285714em;
}
@media (min-width: 875px) {
  .compare {
    display: flex;
  }
}
.compareButton {
  flex-grow: 1;
  text-align: center;
  color: currentColor;
  background-color: #fff;
  border: 1px solid currentColor;
  cursor: pointer;
  outline: none;
  min-height: 1.428571428571429em;
}
.active {
  background-color: currentColor;
}
.active span {
  color: #fff;
}
.compareButton:first-child {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}
.compareButton:last-child {
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}
