import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import SubCategories from './sub-categories';
import CategoryIcon from './category-icon';
import CloseIcon from '../icons/close-icon';

import config from '../../config';
import { getDictionary } from '../../store/locales';

import * as styles from './styles.module.css';
import { useNavigate } from 'react-router';
import { ParentCategoryId } from '../../../typescript/poi';
import { categories } from '../../constants/categories';

/**
 * The filter categories component. Hosts the category selection for the filter.
 */
const CategorySelection = (): React.ReactElement => {
  const navigate = useNavigate();
  const [parentCategoryId, setParentCategoryId] =
    useState<ParentCategoryId | null>(null);

  /**
   * Calls the select category action.
   */
  const selectCategory = (category: string) => {
    navigate(`category/${category}`);
  };

  /**
   * Sets state for a selected category
   * and calls category select handler from props.
   */
  const onParentCategorySelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    const category = event.currentTarget.id as ParentCategoryId;
    const categoryHasChilds = categories.some(
      (categoryConfig) => categoryConfig.parent === category
    );

    setParentCategoryId(category);

    if (!categoryHasChilds) {
      selectCategory(category);
    }
  };

  /**
   * Sets state for a selected sub-category
   * and calls category select handler from props.
   */
  const onSubcategorySelect = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.stopPropagation();

    const subCategory = event.currentTarget.id;
    selectCategory(subCategory);
  };

  /**
   * Calls the remove category action.
   */
  const onCategoryRemove = () => {
    setParentCategoryId(null);
  };

  /**
   * Render the categories list.
   */
  return (
    <div className={styles.categoriesContainer}>
      <Header
        parentCategoryId={parentCategoryId}
        onCategoryRemove={onCategoryRemove}
      />
      <Categories
        parentCategoryId={parentCategoryId}
        onSubcategorySelect={onSubcategorySelect}
        onParentCategorySelect={onParentCategorySelect}
      />
    </div>
  );
};

export default CategorySelection;

/**
 * Returns the header for the categories list.
 */
const Header = ({
  parentCategoryId,
  onCategoryRemove
}: {
  parentCategoryId: ParentCategoryId | null;
  onCategoryRemove: () => void;
}) => {
  const locales = useSelector(getDictionary);
  const title = parentCategoryId
    ? locales[parentCategoryId]
    : locales.lookingFor;

  return (
    <header className={styles.categoriesHeader}>
      {title}
      {parentCategoryId && (
        <CloseIcon
          className={styles.removeCategory}
          width={25}
          height={25}
          onClick={onCategoryRemove}
        />
      )}
    </header>
  );
};

/**
 * Decides what kind of categories we need to render.
 * The parents with contact, the contact options or the subcategory.
 */
const Categories = ({
  parentCategoryId,
  onSubcategorySelect,
  onParentCategorySelect
}: {
  parentCategoryId: ParentCategoryId | null;
  onSubcategorySelect: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
  onParentCategorySelect: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) => {
  if (parentCategoryId) {
    return (
      <SubCategories
        onSubcategorySelect={onSubcategorySelect}
        parentCategoryId={parentCategoryId}
      />
    );
  }

  return <ParentCategories onParentCategorySelect={onParentCategorySelect} />;
};

/**
 * Returns the parent categories list.
 */
const ParentCategories = ({
  onParentCategorySelect
}: {
  onParentCategorySelect: (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => void;
}) => {
  const locales = useSelector(getDictionary);

  const openWhatsappChat = () => {
    window.open(`https://chat.whatsapp.com/${config.whatsappSosId}`, '_blank');
  };

  return (
    <div className={styles.categoriesContent}>
      {categories
        .filter((category) => !category.parent)
        .map((category) => (
          <CategoryIcon
            key={locales[category.id]}
            title={locales[category.id]}
            category={category}
            onClick={onParentCategorySelect}
          />
        ))}
    </div>
  );
};
