import React from 'react';
import cx from 'classnames';

import * as icons from '../icons/icons';

import * as styles from './styles.module.css';

type Props = {
  text?: string;
  iconName?: string;
  onClick?: () => void;
  className?: string;
  iconClassName?: string;
  disabled?: boolean;
};

const Button = ({
  text,
  iconName,
  iconClassName,
  onClick,
  className,
  disabled
}: Props): React.ReactElement => {
  let Icon = null;
  if (iconName) {
    // @ts-ignore
    Icon = icons[iconName];
  }

  const containerClassName = cx(
    styles.container,
    className,
    Icon && styles.hasIcon
  );
  const iconClassNames = cx(styles.icon, iconClassName);

  return (
    <button
      disabled={disabled}
      className={containerClassName}
      onClick={onClick}
    >
      {text && <span className={styles.text}>{text}</span>}
      {Icon && (
        <div className={styles.iconContainer}>
          <Icon height={24} width={24} className={iconClassNames} />
        </div>
      )}
    </button>
  );
};

export default Button;
