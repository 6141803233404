import React from 'react';
import cx from 'classnames';

type Props = {
  height: number;
  width: number;
  className?: string;
  fill?: string;
};

/**
 * The karunis symbol
 */
const KarunisIcon = (props: Props): React.ReactElement => {
  const {height, width, className, fill} = props;

  const iconClassNames = cx('karunis-symbol icon', className);

  return (
    <svg
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg">
      <title>Karunis</title>
      <path
        d="M20.833 4.167h12.5V37.5L66.667 4.167h16.666l-37.5 37.5h25v12.5h-25l37.5 41.666H66.667l-33.334-37.5v37.5h-12.5V4.167z"
        fill-rule="nonzero"
        fill={fill}
      />
    </svg>
  );
};

export default KarunisIcon;
