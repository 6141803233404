import React from 'react';
import cx from 'classnames';
import ReactMarkdown from 'react-markdown';

import HomeIcon from '../icons/home-icon';
import TimeIcon from '../icons/time-icon';
import EmailIcon from '../icons/email-icon';
import PhoneIcon from '../icons/phone-icon';

import CookedFoodIcon from '../icons/cooked-food-icon';
import DefaultAgeIcon from '../icons/default-age-icon';
import DefaultGenderIcon from '../icons/default-gender-icon';
import DogsIcon from '../icons/dogs-icon';
import MaleIcon from '../icons/male-icon';
import FemaleIcon from '../icons/female-icon';
import UnderAgeIcon from '../icons/under-age-icon';
import FullAgeIcon from '../icons/full-age-icon';
import FreeIcon from '../icons/free-icon';
import LockerIcon from '../icons/locker-icon';
import WifiIcon from '../icons/wifi-icon';

const filterIcons = {
  cookedFood: CookedFoodIcon,
  defaultAge: DefaultAgeIcon,
  defaultGender: DefaultGenderIcon,
  dogs: DogsIcon,
  male: MaleIcon,
  female: FemaleIcon,
  underAge: UnderAgeIcon,
  fullAge: FullAgeIcon,
  free: FreeIcon,
  locker: LockerIcon,
  wifi: WifiIcon
};

import * as styles from './styles.module.css';
import { Poi } from '../../../typescript/poi';

type Props = {
  poi?: Poi;
  onClick: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

/**
 * The info
 */
function CompareInfo({ poi, onClick }: Props): React.ReactElement {
  if (!poi) {
    return <div className={`${styles.info} ${styles.empty}`} />;
  }

  const headerClasses = cx(styles.header, 'overlay');
  const titleClasses = cx(styles.title);
  const filters = Object.keys(poi.convenienceFilter).filter(
    // @ts-ignore
    (filter) => poi && poi.convenienceFilter[filter]
  );

  return (
    <div className={styles.info} onClick={onClick}>
      <header className={headerClasses}>
        <div className={titleClasses}>{poi.address.name}</div>
      </header>

      <section className={styles.filter}>
        {filters.map((filter) => renderFilterIcon(filter))}
      </section>

      <section className={styles.content}>
        <div className={styles.contentItem}>
          <HomeIcon className={styles.icon} width={22} height={22} />
          <p>
            {`${poi.address.street} ${poi.address.streetnumber}`}
            <br />
            {`${poi.address.zip} ${poi.address.city}`}
          </p>
        </div>

        {poi.address.openingHours && (
          <div className={styles.contentItem}>
            <TimeIcon className={styles.icon} width={22} height={22} />
            <p>
              <ReactMarkdown>{poi.address.openingHours || ''}</ReactMarkdown>
            </p>
          </div>
        )}

        {poi.address.phone && (
          <div className={styles.contentItem}>
            <PhoneIcon className={styles.icon} width={22} height={22} />
            <p>{poi.address.phone}</p>
          </div>
        )}

        {poi.address.email && (
          <div className={styles.contentItem}>
            <EmailIcon className={styles.icon} width={22} height={22} />
            <p>{poi.address.email}</p>
          </div>
        )}
      </section>
    </div>
  );
}

/**
 * Returns the icon component for a given filter type.
 */
function renderFilterIcon(filter: string) {
  // @ts-ignore
  const Icon = filterIcons[filter];

  return <Icon width={25} height={25} className={styles.icon} />;
}

export default CompareInfo;
