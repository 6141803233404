import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import sortby from 'lodash.sortby';
// import isEqual from 'lodash.isequal';

import Input from './input';
import SuggestList from './suggest-list';
import ListIcon from '../icons/list-icon';
import MapIcon from '../icons/map-icon';

import * as styles from './styles.module.css';

import { filterPois, getActivePoi, addComparePoi } from '../../store/pois';
import { setCenter, isAppOffline } from '../../store/app';
import { getDictionary } from '../../store/locales';
import { GooglePlaceSuggest, Suggest } from '../../../typescript/poi';
import { StoreState } from '../../../typescript/store';
import { useNavigate } from 'react-router';

type Props = {
  children: React.ReactNode;
};

/**
 * Returns the component for the location filter.
 */
const LocationList = ({ children }: Props): React.ReactElement => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [googlePlaces, setGooglePlaces] = useState<Array<GooglePlaceSuggest>>(
    []
  );
  const [filteredSuggests, setFilteredSuggests] = useState<
    Array<Suggest | GooglePlaceSuggest>
  >([]);
  const [suggests, setSuggests] = useState<Array<Suggest>>([]);
  const [input, setInput] = useState('');
  const [fullScreen, setFullScreen] = useState(false);
  const [showList, setShowList] = useState(false);

  const pois = useSelector(filterPois);

  const activePoi = useSelector(getActivePoi);
  const category = useSelector((state: StoreState) => state.filter.category);
  const locales = useSelector(getDictionary);
  const autocompleteService = useSelector(
    (state: StoreState) => state.app.autocompleteService
  );
  const geocoder = useSelector((state: StoreState) => state.app.geocoder);
  const isOffline = useSelector(isAppOffline);
  const isCompareMode = useSelector(
    (state: StoreState) => state.app.isCompareMode
  );

  /**
   * Hides the suggestion list.
   */
  const hideList = () => {
    if (!fullScreen) {
      setShowList(false);
    }
  };

  /**
   * Filters the pois.
   */
  const filterSuggestions = (suggestions: Array<Suggest>) => {
    setFilteredSuggests([
      ...googlePlaces,
      ...suggestions.filter((suggest) => {
        const searchfield = [
          suggest.label,
          suggest.subTitle,
          suggest.poi.address.formattedAddress
        ]
          .join()
          .toLowerCase();

        return searchfield.indexOf(input.toLowerCase()) !== -1;
      })
    ]);
  };

  /**
   * Handler to open and close the suggestion list.
   */
  const toggleList = () => {
    const fullScreenList = !showList || !fullScreen;

    setShowList(fullScreenList);
    setFullScreen(fullScreenList);

    filterSuggestions(suggests);
  };

  /**
   * Adds google results to suggestion list.
   */
  const addGooglePlaces = (results: any[]) => {
    setGooglePlaces(
      results.map((place) => ({
        id: place.id,
        label: place.description,
        subTitle: '',
        place
      }))
    );
    filterSuggestions(suggests);
  };

  /**
   * Handles the input field changes.
   */
  const onInput = (inputValue: string) => {
    setInput(inputValue);

    if (inputValue) {
      if (!isOffline && autocompleteService) {
        autocompleteService.getPlacePredictions(
          { input: inputValue, componentRestrictions: { country: 'de' } },
          addGooglePlaces
        );
      }
      setShowList(true);
    } else {
      hideList();
      setGooglePlaces([]);
    }
  };

  /**
   * Builds up a suggestion list for the suggestion list component.
   */
  useEffect(() => {
    const poiSuggest: Suggest[] = pois.map((poi) => ({
      id: poi.id,
      label: poi.address.name,
      subTitle: poi.address.formattedAddress,
      poi
    }));

    setSuggests(sortby(poiSuggest, 'label'));
  }, [pois]);

  useEffect(() => {
    filterSuggestions(suggests);
  }, [suggests, input]);

  /**
   * Dispatches the selected suggest poi.
   */
  const onSuggestSelect = (index: number) => {
    const suggest = filteredSuggests[index];
    hideList();

    const internalSuggest = suggest as Suggest;
    const googlePlaceSuggest = suggest as GooglePlaceSuggest;

    if (isCompareMode && internalSuggest.poi) {
      dispatch(addComparePoi(internalSuggest.poi));
      return;
    }

    if (googlePlaceSuggest.place) {
      geocoder &&
        geocoder.geocode(
          { placeId: googlePlaceSuggest.place.place_id },
          (results, status) => {
            if (status === google.maps.GeocoderStatus.OK) {
              const gmaps = results[0],
                location = gmaps.geometry.location;

              dispatch(
                setCenter({
                  lat: location.lat(),
                  lng: location.lng()
                })
              );
            }
          }
        );
      return;
    }

    if (activePoi && suggest.id === activePoi.id) {
      navigate(`/category/${category}/`);
    } else if (internalSuggest.poi) {
      navigate(`/category/${category}/poi/${internalSuggest.poi.meta.slug}`);
    }
  };

  const containerClasses = cx(
    styles.container,
    fullScreen && styles.containerFullscreen
  );
  const headerClasses = cx(styles.header, 'overlay');

  return (
    <div className={containerClasses}>
      <header className={headerClasses}>
        {!showList ? (
          <ListIcon className={styles.listIcon} onClick={toggleList} />
        ) : (
          <MapIcon className={styles.mapIcon} onClick={toggleList} />
        )}

        <Input
          onChange={onInput}
          onBlur={hideList}
          onEscape={hideList}
          value={input}
          placeholder={locales.lookingFor}
        />
      </header>

      {children}

      <SuggestList
        suggests={filteredSuggests}
        isHidden={!showList}
        small={!fullScreen}
        onSuggestSelect={onSuggestSelect}
        withFillImages={!isOffline || !isCompareMode}
      />
    </div>
  );
};

export default LocationList;
