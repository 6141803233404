import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The default age icon
 */
const DefaultAgeIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('default-age-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="default-age icon">
      <title>DefaultAgeIcon</title>
      <path
        fill={fill}
        d="M9.58,23.77l-4-1.38V20.5L11.55,23v1.6L5.63,27.07v-1.9Zm-2-7,4,1.38v1.89L5.63,17.59V16l5.92-2.49v1.9Zm14,9.67H19.06v-9.8l-3,.94v-2l5.3-1.9h.27Zm12.5-9.4a3,3,0,0,1-.47,1.65,3.35,3.35,0,0,1-1.28,1.15,3.72,3.72,0,0,1,1.48,1.23A3.17,3.17,0,0,1,34.37,23a3.36,3.36,0,0,1-1.16,2.69,4.71,4.71,0,0,1-3.16,1,4.73,4.73,0,0,1-3.16-1A3.35,3.35,0,0,1,25.72,23a3.19,3.19,0,0,1,.54-1.85,3.64,3.64,0,0,1,1.47-1.23,3.32,3.32,0,0,1-1.28-1.15A3,3,0,0,1,26,17.08a3.32,3.32,0,0,1,1.09-2.61,4.3,4.3,0,0,1,3-1,4.31,4.31,0,0,1,3,1A3.3,3.3,0,0,1,34.1,17.08Zm-2.28,5.7a1.84,1.84,0,0,0-.48-1.34,1.93,1.93,0,0,0-2.59,0,2.08,2.08,0,0,0,0,2.66,2,2,0,0,0,2.61,0A1.83,1.83,0,0,0,31.82,22.78Zm-.26-5.58a1.76,1.76,0,0,0-.4-1.2,1.61,1.61,0,0,0-2.22,0,1.75,1.75,0,0,0-.4,1.21,1.84,1.84,0,0,0,.4,1.23,1.56,1.56,0,0,0,2.23,0A1.85,1.85,0,0,0,31.56,17.2Z"
      />
    </svg>
  );
};

export default DefaultAgeIcon;
