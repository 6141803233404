import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_close
 */
const CloseIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('close-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      aria-label="close icon">
      <title>CloseIcon</title>
      <path
        fill={fill}
        d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
      />
    </svg>
  );
};

export default CloseIcon;
