import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * https://design.google.com/icons/#ic_close
 */
const ListIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('list-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      aria-label="close icon">
      <title>ListIcon</title>
      <path
        fill={fill}
        d="M11,14 L4,14 L4,12 L11,12 L11,14 Z M14,10 L4,10 L4,8 L14,8 L14,10 Z M14,6 L4,6 L4,4 L14,4 L14,6 Z M16,0 L2,0 C0.89,0 0,0.89 0,2 L0,16 C0,17.1045695 0.8954305,18 2,18 L16,18 C17.1045695,18 18,17.1045695 18,16 L18,2 C18,0.89 17.1,0 16,0 Z"
      />
    </svg>
  );
};
export default ListIcon;
