{
  "name": "ubilabs-refugee-maps",
  "version": "1.0.0",
  "scripts": {
    "prestart": "rm -rf public && mkdir -p public",
    "start": "parcel src/index.ejs --dist-dir public",
    "prebuild-staging": "rm -rf build/staging && mkdir -p build/staging",
    "build-staging": "NODE_ENV=staging parcel build src/index.ejs --dist-dir build --target staging",
    "postbuild-staging": "cp -r src/assets/public build/staging/assets && workbox injectManifest workbox-config-staging.js",
    "prebuild-production": "rm -rf build/production && mkdir -p build/production",
    "build-production": "parcel build src/index.ejs --dist-dir build --target production",
    "postbuild-production": "cp -r src/assets/public build/production/assets && workbox injectManifest workbox-config-prod.js",
    "test": "eslint && tsc --noEmit"
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/ubilabs/ubilabs-refugee-maps.git"
  },
  "keywords": [],
  "author": "",
  "license": "ISC",
  "bugs": {
    "url": "https://github.com/ubilabs/ubilabs-refugee-maps/issues"
  },
  "homepage": "https://github.com/ubilabs/ubilabs-refugee-maps#readme",
  "dependencies": {
    "@parcel/config-default": "^2.4.0",
    "@parcel/transformer-css": "^2.4.1",
    "@types/googlemaps": "^3.43.3",
    "@types/lodash.filter": "^4.6.6",
    "@types/lodash.isequal": "^4.5.5",
    "@types/lodash.sortby": "^4.7.6",
    "@types/marked": "^4.0.3",
    "@types/node": "^17.0.22",
    "@types/react-dom": "^17.0.14",
    "@types/react-helmet": "^6.1.5",
    "@types/react-router": "^5.1.18",
    "@types/redux-logger": "^3.0.9",
    "classnames": "^2.3.1",
    "eslint": "^8.12.0",
    "history": "^5.3.0",
    "isomorphic-fetch": "^3.0.0",
    "lodash.filter": "^4.6.0",
    "lodash.isequal": "^4.5.0",
    "lodash.sortby": "^4.7.0",
    "marked": "^4.0.12",
    "normalize": "^0.3.1",
    "normalize.css": "^8.0.1",
    "parcel": "^2.4.0",
    "parcel-transformer-ejs": "^0.2.0",
    "react": "^17.0.2",
    "react-dom": "^17.0.2",
    "react-helmet": "^6.1.0",
    "react-markdown": "^8.0.1",
    "react-redux": "^7.2.6",
    "react-router": "^6.2.2",
    "react-router-dom": "^6.2.2",
    "redux": "^4.1.2",
    "redux-dynamic-middlewares": "^2.0.0",
    "redux-logger": "^3.0.6",
    "redux-thunk": "^2.4.1",
    "swr": "^1.2.2",
    "typescript": "^4.6.3",
    "typescript-plugin-css-modules": "^3.4.0",
    "workbox-cli": "^6.5.3",
    "workbox-precaching": "^6.5.2",
    "workbox-routing": "^6.5.2",
    "workbox-strategies": "^6.5.2",
    "workbox-window": "^6.5.1"
  },
  "devDependencies": {
    "@parcel/packager-raw-url": "^2.4.0",
    "@parcel/transformer-webmanifest": "^2.4.0",
    "parcel-transformer-ts-css-modules": "^1.0.14",
    "postcss": "^8.4.12",
    "postcss-modules": "^4.3.1"
  },
  "targets": {
    "staging": {
      "context": "browser",
      "publicUrl": "/refugeo/staging"
    },
    "production": {
      "context": "browser",
      "publicUrl": "/"
    }
  }
}
