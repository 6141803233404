.locationIconContainer {
  cursor: pointer;
  position: absolute;
  bottom: 5.9375em;
  right: 0.625em;
  padding: 0.25em;
  background-color: #fff;
  border-radius: 0.125em;
  transform: translateY(-50%);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
}
.requestLocationIcon {
  display: block;
  fill: #666;
}
.spinner {
  display: block;
  fill: none;
  stroke-width: 0.5em;
  width: 2rem;
  height: 2rem;
  animation: rotator 1.5s linear infinite;
}
.path {
  stroke-dasharray: 200;
  stroke-dashoffset: 0;
  transform-origin: center;
  stroke: #666;
  animation: dash 1.5s ease-in-out infinite;
}
@-moz-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-o-keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 200;
  }
  50% {
    stroke-dashoffset: 50;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 200;
    transform: rotate(450deg);
  }
}
@-moz-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@-webkit-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@-o-keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
