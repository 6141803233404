import React from 'react';
import cx from 'classnames';

type Props = {
  fill?: string;
  height?: number;
  width?: number;
  onClick?: () => void;
  className?: string;
};

/**
 * The male icon
 */
const MaleIcon = (props: Props): React.ReactElement => {
  const {fill, height, width, onClick, className} = props;

  const iconClassNames = cx('male-icon icon', className);

  return (
    <svg
      onClick={onClick}
      className={iconClassNames}
      width={width}
      height={height}
      viewBox="0 0 40 40"
      aria-label="male icon">
      <title>MaleIcon</title>
      <path
        fill={fill}
        d="M16,16a7.91,7.91,0,0,1,4.63,1.48l6.81-6.81H21.33V8H32V18.67H29.33V12.55l-6.81,6.79A8.09,8.09,0,0,1,24,24a8,8,0,1,1-8-8m0,2.67A5.33,5.33,0,1,0,21.33,24,5.33,5.33,0,0,0,16,18.67Z"
      />
    </svg>
  );
};

export default MaleIcon;
